import {
    SET_USERNAME,
    SET_IS_LOGGEDIN,
    SET_BASIC_AUTH,
    SET_ROLE_USER,
    SET_ROLE_ADMIN,
    SET_API_KEY,
    DESTROY_SESSION
} from '../constants/userconstants';

export function setUsername(payload) {
    return { type: SET_USERNAME, payload }
};

export function setLoggedIn(payload) {
    return { type: SET_IS_LOGGEDIN, payload }
}

export function setBasicAuth(payload) {
    return { type: SET_BASIC_AUTH, payload }
}

export function setRoleUser(payload) {
    return { type: SET_ROLE_USER, payload }
}

export function setRoleAdmin(payload) {
    return { type: SET_ROLE_ADMIN, payload }
}

export function setApiKey(payload) {
    return { type: SET_API_KEY, payload }
}

export function destroySession(payload) {
    return { type: DESTROY_SESSION, payload }
}