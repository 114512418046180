import { IonCard, IonText, IonImg } from '@ionic/react';
import React from 'react';

import './MenuCard.css';

export default class MenuCard extends React.Component {
    render() {
        return (
            <>
                {(this.props.show) ?
                    <IonCard
                        onClick={this.props.onClick}
                        className='card-main'>
                        <IonImg
                            src={'../assets/' + this.props.imageName}
                            className='card-image' />
                        <IonText
                            className={`card-title ${this.props.highlight ? 'highlight' : ''}`}>
                            {this.props.title}
                        </IonText>
                    </IonCard> : null}
            </>
        );
    }
}
