import { IonContent, IonPage, IonIcon, IonList, IonItem, IonLabel } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';

import '../Settings.css';

import Topbar from '../../js/components/Topbar';

import SideMenu from '../../js/components/SideMenu';
import '../../overall.css'

import { connect } from 'react-redux';

class SettingsValues extends React.Component {
    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue='Standardwerte' />
                            <IonList className='settings-list'>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values/revenue') }}>
                                    <IonLabel>
                                        Umsatz
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values/products') }}>
                                    <IonLabel>
                                        Beste Produkte
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values/flights') }}>
                                    <IonLabel>
                                        Beste Flüge
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values/crewmembers') }}>
                                    <IonLabel>
                                        Beste Crewmitglieder
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values/sales') }}>
                                    <IonLabel>
                                        Crewumsätze
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings
    }
}

export default connect(mapStateToProps, null)(SettingsValues);