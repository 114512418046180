import { IonContent, IonPage, IonList } from '@ionic/react';
import React from 'react';

import '../../Settings.css';

import Topbar from '../../../js/components/Topbar';

import SideMenu from '../../../js/components/SideMenu';
import '../../../overall.css'

import { connect } from 'react-redux';

class SettingsValuesSales extends React.Component {
    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                headlineValue='Crewumsätze' />
                            <IonList className='settings-list'>

                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings
    }
}

export default connect(mapStateToProps, null)(SettingsValuesSales);