import axios from "axios";

export default class API {
    constructor() {
        // this.baseURL = "http://test-crewplaning.sundair.com/rest/";
        this.baseURL = "https://api-air41.flyair41.de/rest/";
        this.apiKey = "037ae1f2ba244a2a93b24121743bfdd6";
    }

    getISOString(type, date) {
        let d = new Date(date);
        let end = "T00:00:00Z";

        if (type === "START") {
            end = "T00:00:00Z";
        } else if (type === "END") {
            end = "T23:59:59Z";
        }

        return "" + d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + d.getDate().toString().padStart(2, "0") + end;
    }

    createCustomHeader(basicauth) {
        return {
            apiKey: this.apiKey,
            Authorization: "Basic " + basicauth,
        };
    }

    async login(username, password) {
        let base = btoa(username + ":" + password);

        const headers = {
            apiKey: this.apiKey,
            Authorization: "Basic " + base,
        };

        return new Promise(async (resolve) => {
            await axios
                .get(this.baseURL + "acm/acl/login", {
                    headers,
                })
                .then(function (response) {
                    console.log(response);

                    let data = {};
                    if (response.data) {
                        data = response.data;
                    }

                    let grants = [];
                    if (data.grants) {
                        grants = data.grants;
                    }

                    let apiKey = "";
                    if (data.carriers && data.carriers.length > 0) {
                        apiKey = data.carriers[0].apiKey;
                    }

                    resolve({
                        username: username,
                        basicAuth: base,
                        grants: grants,
                        apiKey: apiKey,
                        success: true,
                    });
                })
                .catch(function (error) {
                    console.log(error);

                    resolve({
                        username: "",
                        basicAuth: "",
                        grants: [],
                        apiKey: "",
                        success: false,
                    });
                });
        });
    }

    async getProductsAndCategories(basicauth) {
        return new Promise(async (resolve) => {
            await axios
                .get(this.baseURL + "shop/category", {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    let prods = response.data;
                    let cats = [];

                    if (prods.length === 0) {
                        prods = [];
                    }

                    for (let i = 0; i < prods.length; i++) {
                        cats.push({
                            name: prods[i].name,
                            code: prods[i].code,
                            id: prods[i].id,
                        });
                    }

                    resolve([prods, cats]);
                })
                .catch(function (error) {
                    resolve([[], []]);
                });
        });
    }

    async getBestProducts(startDate, endDate, aptFromCode, aptToCode, flightNo, basicauth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            let body = {
                fromDate: start,
                toDate: end,
            };

            if (aptFromCode && aptToCode) {
                body = Object.assign(body, { aptFromCode: aptFromCode, aptToCode: aptToCode });
            }

            if(flightNo) {
                body = Object.assign(body, { flightNo: flightNo });
            }

            await axios
                .put(this.baseURL + "shop/stats/product/top", body, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    let prods = response.data;

                    if (prods.length === 0) {
                        prods = [];
                    }

                    resolve(prods);
                })
                .catch(function (error) {
                    resolve([]);
                });
        });
    }


    async getProductsReport(startDate, endDate, aptFromCode, aptToCode, flightNo, basicauth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            let body = {
                fromDate: start,
                toDate: end,
            };

            if (aptFromCode && aptToCode) {
                body = Object.assign(body, { aptFromCode: aptFromCode, aptToCode: aptToCode });
            }

            if(flightNo) {
                body = Object.assign(body, { flightNo: flightNo });
            }

            await axios
                .put(this.baseURL + "shop/stats/product/top/download", body, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    let hdrs = response.headers["air41-status"];

                    switch (hdrs) {
                        case 'OK': // 'OK'
                            const url = window.URL.createObjectURL(new Blob([response.data], { type:"application/csv" })),
                                link = document.createElement('a');
                                

                            link.href = url;
                            link.setAttribute('download', 'report.csv');
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            resolve(true);
                            break;
                        default: // 'ERROR'
                            // show error
                            resolve(false);
                            break;
                    }
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async getBestFlights(startDate, endDate, flightNo, basicauth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            let body = {
                fromDate: start,
                toDate: end,
            };

            if (flightNo) {
                body = Object.assign(body, { flightNo: flightNo });
            }

            await axios
                .put(this.baseURL + "shop/stats/flight/top", body, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    let data = response.data;

                    if (data.length === 0) {
                        data = [];
                    }

                    resolve(data);
                })
                .catch(function (error) {
                    resolve([]);
                });
        });
    }

    async createProduct(code, description, idCategory, name, price, basicauth) {
        let prod = {
            code,
            id: null,
            description,
            idCategory,
            name,
            price1: price,
            price2: 0.0,
        };

        return new Promise(async (resolve) => {
            await axios
                .put(this.baseURL + `shop/product/`, prod, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async editProduct(code, description, id, idCategory, name, price, basicauth) {
        let prod = {
            code,
            id,
            description,
            idCategory,
            name,
            price1: price,
            price2: 0,
        };

        return new Promise(async (resolve) => {
            await axios
                .put(this.baseURL + `shop/product/`, prod, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async deleteProduct(id, basicauth) {
        return new Promise(async (resolve) => {
            await axios
                .delete(this.baseURL + `shop/product/${id}`, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async createCategory(code, description, name, basicauth) {
        let cat = {
            code,
            id: null,
            description: description,
            name,
        };

        return new Promise(async (resolve) => {
            await axios
                .put(this.baseURL + `shop/category/`, cat, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async editCategory(code, name, id, basicauth) {
        let cat = {
            code,
            id,
            name,
        };

        return new Promise(async (resolve) => {
            await axios
                .put(this.baseURL + `shop/category/`, cat, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async deleteCategory(id, basicauth) {
        return new Promise(async (resolve) => {
            await axios
                .delete(this.baseURL + `shop/category/${id}`, {
                    headers: this.createCustomHeader(basicauth),
                })
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });
        });
    }

    async getCommission(startDate, endDate, basicauth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            await axios
                .put(
                    this.baseURL + "shop/stats/commission",
                    {
                        fromDate: start,
                        toDate: end,
                    },
                    {
                        headers: this.createCustomHeader(basicauth),
                    }
                )
                .then(function (response) {
                    let data = response.data;

                    if (data.length === 0) {
                        data = [];
                    }

                    resolve(data);
                })
                .catch(function (error) {
                    resolve([]);
                });
        });
    }

    async getBestCrewmembers(startDate, endDate, basicauth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            await axios
                .put(
                    this.baseURL + "shop/stats/resource/top",
                    {
                        fromDate: start,
                        toDate: end,
                    },
                    {
                        headers: this.createCustomHeader(basicauth),
                    }
                )
                .then(function (response) {
                    let data = response.data;

                    if (data.length === 0) {
                        data = [];
                    }

                    resolve(data);
                })
                .catch(function (error) {
                    resolve([]);
                });
        });
    }

    async getRevenue(startDate, endDate, basicAuth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            await axios
                .put(
                    this.baseURL + "shop/stats/top",
                    {
                        fromDate: start,
                        toDate: end,
                    },
                    {
                        headers: this.createCustomHeader(basicAuth),
                    }
                )
                .then(function (response) {
                    let data = response.data;

                    if (data.length === 0) {
                        data = [];
                    }

                    resolve(data);
                })
                .catch(function (error) {
                    resolve([]);
                });
        });
    }

    async getLastUpdateTime(basicAuth) {
        return new Promise(async (resolve) => {
            await axios
                .get(this.baseURL + "shop/stats/lastorder", {
                    headers: this.createCustomHeader(basicAuth),
                })
                .then(function (response) {
                    let data = response.data.lastOrderDate;

                    if (data) {
                        resolve(new Date(data));
                    } else {
                        resolve(null);
                    }
                })
                .catch(function (error) {
                    resolve(null);
                });
        });
    }

    async getNumberOfOrders(startDate, endDate, basicAuth) {
        return new Promise(async (resolve) => {
            //convert date objects to ISO strings
            let start = this.getISOString("START", startDate);
            let end = this.getISOString("END", endDate);

            await axios
                .put(
                    this.baseURL + "shop/stats/numoforders",
                    {
                        fromDate: start,
                        toDate: end,
                    },
                    {
                        headers: this.createCustomHeader(basicAuth),
                    }
                )
                .then(function (response) {
                    resolve({
                        cash: response.data.numOfOrders.CS,
                        creditcard: response.data.numOfOrders.CC,
                        total: response.data.numOfOrders.total,
                    });
                })
                .catch(function (error) {
                    resolve({ cash: 0, creditcard: 0, total: 0 });
                });
        });
    }
}
