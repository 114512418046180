import { IonText } from '@ionic/react';
import React from 'react';

import './Buttonbar.css'

export default class Buttonbar extends React.Component {
    _renderOptions() {
        let ret = [];

        if (this.props.options) {
            this.props.options.forEach((option, index) => {

                let className = 'buttonbar-button';
                if (this.props.activeIndex === index) {
                    className += ' active';
                }

                ret.push(
                    <IonText key={index} onClick={() => { this._setActive(index) }} className={className}>
                        {option}
                    </IonText>
                );
            });
        }

        return ret;
    }

    _setActive(idx) {
        this.props.onChange(idx, this.props.values[idx]);
    }

    render() {
        return (
            <>
                <div className='buttonbar-button-container'>
                    {this._renderOptions()}
                </div>
            </>
        )
    }
}