import { IonIcon, IonText } from '@ionic/react';
import { addOutline, calendarOutline } from 'ionicons/icons';
import React from 'react';

import './TextButton.css'

export default class TextButton extends React.Component {
    _renderIcon() {
        let ret = [];

        switch (this.props.iconStyle) {
            case 'plus':
                ret.push(
                    <IonIcon key={1} className='textbutton-icon' icon={addOutline} />
                )
                break;
            case 'calendar':
                ret.push(
                    <IonIcon key={2} className='textbutton-icon' icon={calendarOutline} />
                )
                break;
            default:

        }

        return ret;
    }

    render() {
        return (
            <>
                <IonText
                    className='textbutton-text'
                    onClick={this.props.onClick}>
                    {this._renderIcon()}
                    {this.props.title}
                </IonText>
            </>
        )
    }
}