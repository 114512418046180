import {
    SET_SETTINGS,
    SET_SETTINGS_DARK_MODE,
    SET_SETTINGS_STANDARD_REVENUE_SEGMENT,
    SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT,
    SET_SETTINGS_STANDARD_PRODUCTS_TOP,
    SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT,
    SET_SETTINGS_STANDARD_CREWMEMBERS_TOP,
    SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT,
    SET_SETTINGS_STANDARD_FLIGHTS_TOP,
} from '../constants/settingsconstants';

export function setSettings(payload) {
    return { type: SET_SETTINGS, payload }
};

export function setSettingsDarkMode(payload) {
    return { type: SET_SETTINGS_DARK_MODE, payload }
};

export function setSettingsStandardRevenueSegment(payload) {
    return { type: SET_SETTINGS_STANDARD_REVENUE_SEGMENT, payload }
};

export function setSettingsStandardProductsSegment(payload) {
    return { type: SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT, payload }
};

export function setSettingsStandardProductsTop(payload) {
    return { type: SET_SETTINGS_STANDARD_PRODUCTS_TOP, payload }
};

export function setSettingsStandardCrewmembersSegment(payload) {
    return { type: SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT, payload }
};

export function setSettingsStandardCrewmembersTop(payload) {
    return { type: SET_SETTINGS_STANDARD_CREWMEMBERS_TOP, payload }
};

export function setSettingsStandardFlightsSegment(payload) {
    return { type: SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT, payload }
};

export function setSettingsStandardFlightsTop(payload) {
    return { type: SET_SETTINGS_STANDARD_FLIGHTS_TOP, payload }
};