import { IonModal, IonText, IonButton, IonList } from '@ionic/react';
import React from 'react';

import CustomModal from './CustomModal';

import './DeleteModal.css'

export default class DeleteModal extends CustomModal {
    _deleteMe() {
        this.props.onDeleteButtonPressed({
            name: this.props.obj.name,
            id: this.props.obj.id
        });
    }

    _renderCurrentProds() {
        let ret = [];

        if (this.props.prods) {
            for (let i = 0; i < this.props.prods.length; i++) {
                if (this.props.prods[i].idCategory === this.props.obj.id) {
                    ret.push(
                        <p key={i}>{this.props.prods[i].name}{(i + 1 < this.props.prods.length) ? ', ' : ''}</p>
                    )
                }
            }
        }

        return ret;
    }

    render() {
        return (
            <>
                <IonModal
                    className='custommodal-container'
                    isOpen={this.props.isOpen}
                    backdropDismiss={false}>

                    <div className='custommodal-header-container'>
                        <div className='custommodal-closebutton-container'>
                            {this._renderCloseButton()}
                        </div>
                        <div className='custommodal-headline-container'>
                            <IonText className='custommodal-headline'>{this.props.headlineValue}</IonText>
                        </div>
                    </div>

                    <h2 className='deletemodal-h2'>Soll <span className='deletemodal-h2-span'>{(this.props.obj) ? this.props.obj.name : ''}</span> wirklich gelöscht werden?</h2>
                    <p className='deletemodal-p'>
                        Dieser Vorgang kann nicht rückgängig gemacht werden. {(this.props.obj.idCategory) ? 'Das Produkt' : 'Die Kategorie'} ist danach nicht mehr in der Catering-App verfügbar.
                    </p>

                    {(!this.props.obj.idCategory && this._renderCurrentProds().length > 0) ?
                        <>
                            <h2 className='deletemodal-h2 deletemodal-danger'>ACHTUNG! In der Kategorie befinden sich noch Produkte.</h2>
                            <p className='deletemodal-p deletemodal-danger'>
                                Die folgenden Produkte werden ebenfalls gelöscht, wenn die Kategorie gelöscht wird.
                            </p>
                            <IonList className='deletemodal-product-list'>
                                {this._renderCurrentProds()}
                            </IonList>
                        </>
                        : null}

                    <div className='deletemodal-button-container'>
                        <IonButton mode='ios' onClick={() => { this._deleteMe() }} color='danger'>
                            Löschen
                    </IonButton>
                        <IonButton mode='ios' onClick={() => { this.props.onCloseButtonPressed() }} color='medium'>
                            Abbrechen
                    </IonButton>
                    </div>
                </IonModal>
            </>
        )
    }
}