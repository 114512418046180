import { IonContent, IonPage, IonList, IonLoading, IonToast } from '@ionic/react';
import React from 'react';

import './ProductDatabase.css';

import Topbar from '../js/components/Topbar';
import ButtonbarMultiple from '../js/components/ButtonbarMultiple';
import AddModal from '../js/components/AddModal';
import ErrorMessage from '../js/components/ErrorMessage';

import API from '../API';

import SideMenu from '../js/components/SideMenu';
import SettingsMenu from '../js/components/SettingsMenu';
import '../overall.css'

import ProductDatabaseItem from '../js/components/ProductDatabaseItem';

import { connect } from 'react-redux';

class ProductDatabase extends React.Component {
    state = {
        searchText: '',
        prods: [],
        cats: [],

        filterOptions: [],
        filterCategories: [],
        showModal: false,
        showCalendar: false,
        showLoadingScreen: false,

        categoriesShortName: [],
        categoriesLongName: [],
        categoriesIDs: [],

        showSuccessMessage: false,
        successMessage: '',
        activeSegmentIndex: '0'
    }

    async componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.push({ pathname: '/login', state: { detail: window.location.href.replace('http://localhost:8100', '') } });
        }

        this._loadNewData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== '/database' &&
            this.props.location.pathname.startsWith('/database')) {
            this._loadNewData(this.state.startDate, this.state.endDate);
        }
    }

    async _loadNewData() {
        this.setState({ showLoadingScreen: true });

        //get the user wanted data
        let [prods, cats] = await new API().getProductsAndCategories(this.props.basicAuth);

        let catsShort = [];
        let catsLong = [];
        let catsIDs = [];
        cats.forEach(cat => {
            catsShort.push(cat.code);
            catsLong.push(cat.name);
            catsIDs.push(cat.id);
        });

        let products = [];
        for (let i = 0; i < prods.length; i++) {
            let act = prods[i];

            for (let j = 0; j < act.products.length; j++) {
                products.push(act.products[j]);
            }
        }

        //sort products by name and then by cat
        products.sort((a, b) => {
            return (a.name > b.name) ? 1 : -1;
        });

        this.setState({ prods: products, cats: cats });
        this.setState({ categoriesLongName: catsLong, categoriesShortName: catsShort, categoriesIDs: catsIDs });

        this.setState({ showLoadingScreen: false });
    }

    _showHighlight(text) {
        if (text) {
            return <span className='text-highlight'>{text}</span>;
        } else {
            return null;
        }
    }

    _includesCategory(cat) {
        if (this.state.filterCategories.length > 0) {
            return this.state.filterCategories.includes(cat);
        } else {
            return true;
        }
    }

    async _deleteItem(name, id) {
        await new API().deleteProduct(id);
    }

    async _somethingHasChanged() {
        await this.refs['product-list'].closeSlidingItems();
        this._loadNewData();
    }

    _displayProductList() {
        let ret = [];

        //check if user wants to see prods or cats
        //i personaly just choose cats over all ;)

        let relevantItems = this.state.prods;
        let article = true;
        if (this.state.activeSegmentIndex === '1') {
            //cats
            relevantItems = this.state.cats;
            article = false;
        }

        relevantItems.forEach((prod, index) => {
            //apply filter set by user
            let filter = true;
            if (article) {
                filter = this._includesCategory(prod.idCategory);
            }

            if (filter) {
                if (article) {
                    //check if user input in searchbar fits the 
                    //product name 
                    //or product category
                    //or product category shortname
                    //or product short name
                    if (prod.name.toLowerCase().includes(this.state.searchText.toLowerCase())) {
                        ret.push(
                            <ProductDatabaseItem
                                key={index}
                                type='ARTICLE'
                                obj={prod}
                                hightlight={this.state.searchText.toLocaleLowerCase()}
                                categories={[this.state.categoriesIDs, this.state.categoriesShortName, this.state.categoriesLongName]}
                                onChange={() => { this._somethingHasChanged() }} />
                        )
                    } else if (this.state.categoriesLongName[this.state.categoriesIDs.indexOf(prod.idCategory)].toLowerCase().includes(this.state.searchText.toLowerCase())) {
                        ret.push(
                            <ProductDatabaseItem
                                key={index}
                                type='ARTICLE'
                                obj={prod}
                                categories={[this.state.categoriesIDs, this.state.categoriesShortName, this.state.categoriesLongName]}
                                onChange={() => { this._somethingHasChanged() }} />
                        )
                    } else if (this.state.categoriesLongName[this.state.categoriesIDs.indexOf(prod.idCategory)].toLowerCase().includes(this.state.searchText.toLowerCase())) {
                        ret.push(
                            <ProductDatabaseItem
                                key={index}
                                type='ARTICLE'
                                obj={prod}
                                categories={[this.state.categoriesIDs, this.state.categoriesShortName, this.state.categoriesLongName]}
                                onChange={() => { this._somethingHasChanged() }} />
                        )
                    } else if (prod.code.toLowerCase().includes(this.state.searchText.toLowerCase())) {
                        ret.push(
                            <ProductDatabaseItem
                                key={index}
                                type='ARTICLE'
                                obj={prod}
                                categories={[this.state.categoriesIDs, this.state.categoriesShortName, this.state.categoriesLongName]}
                                onChange={() => { this._somethingHasChanged() }} />
                        )
                    }
                } else {
                    //show cateogries
                    if (prod.name.toLowerCase().includes(this.state.searchText.toLowerCase())) {
                        ret.push(
                            <ProductDatabaseItem
                                key={index}
                                type='CATEGORY'
                                obj={prod}
                                prods={this.state.prods}
                                hightlight={this.state.searchText.toLocaleLowerCase()}
                                categories={[this.state.categoriesIDs, this.state.categoriesShortName, this.state.categoriesLongName]}
                                onChange={() => { this._somethingHasChanged() }} />
                        )
                    }
                }

            }
        })

        //check if is data to show
        if (ret.length === 0) {
            ret.push(
                <ErrorMessage key={0} />
            )
        }

        return ret;
    }

    _getCategorieIDByCode(code) {
        let index = this.state.categoriesShortName.indexOf(code);

        if (index > -1) {
            return this.state.categoriesIDs[this.state.categoriesShortName.indexOf(code)];
        } else {
            return '';
        }
    }

    async _changeDatabase(obj) {
        if (obj.type === 'ADD_ARTICLE') {
            await new API().createProduct(obj.code, '', this._getCategorieIDByCode(obj.category), obj.name_de, obj.price, this.props.basicAuth);

            this.setState({ showSuccessMessage: true, successMessage: `Das Produkt <b>${obj.name_de}</b> wurde erfolgreich in die Datenbank aufgenommen.` });
        } else if (obj.type === 'ADD_CATEGORY') {
            await new API().createCategory(obj.code, '', obj.name_de, this.props.basicAuth);

            this.setState({ showSuccessMessage: true, successMessage: `Die Kategorie <b>${obj.name_de}</b> wurde erfolgreich in die Datenbank aufgenommen.` });
        }

        //after changing the database refresh the
        //state because of all the id stuff and so on
        this._loadNewData();
    }

    _changeSegmentFilter(index) {
        //0 = revenue
        //1 = number
        this.setState({ activeSegmentIndex: index });
    }

    render() {
        return (
            <React.Fragment key={0}>
                <IonPage>
                    <IonLoading
                        isOpen={this.state.showLoadingScreen}
                        onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                        cssClass='spinner'
                        spinner={null} />
                    <IonContent fullscreen class='main-container'>
                        <div className='flex-main-container'>
                            <SideMenu />
                            <div className='flex-content-container'>
                                <Topbar
                                    showBackButton={true}
                                    showMenuButton={true}
                                    showImage={true}

                                    headlineValue='Produktdatenbank'
                                    showTextButton={true}
                                    showSegment={true}
                                    segmentElements={['Produkte', 'Kategorien']}
                                    activeSegmentIndex={this.state.activeSegmentIndex}
                                    onSegmentChange={(idx) => { this.setState({ activeSegmentIndex: idx }); this.refs['product-list'].closeSlidingItems(); }}
                                    textButtonValue={`${(this.state.activeSegmentIndex === '0') ? 'Produkt' : 'Kategorie'} hinzufügen`}
                                    textButtonIconStyle='plus'
                                    onTextButtonClick={() => { this.setState({ showCalendar: true }) }}
                                    showSearchBar={true}
                                    searchBarPlaceholder='Produktname, ID oder Kategorie'
                                    onSearchBarChange={(v) => { this.setState({ searchText: v }) }} />

                                <ButtonbarMultiple
                                    hide={this.state.activeSegmentIndex === '1'}
                                    options={this.state.categoriesLongName}
                                    values={this.state.categoriesIDs}
                                    active={this.state.filterOptions}
                                    onChange={(indexes, values) => { this.setState({ filterOptions: indexes, filterCategories: values }) }} />
                                <IonList ref='product-list' className='product-list'>
                                    {this._displayProductList()}
                                </IonList>
                            </div>
                            <SettingsMenu
                                segmentElements={['Produkte', 'Kategorien']}
                                segmentActiveIndex={this.state.activeSegmentIndex}
                                segmentOnChange={(idx) => { this._changeSegmentFilter(idx) }}
                                actionButtonText={`${(this.state.activeSegmentIndex === '0') ? 'Produkt' : 'Kategorie'} hinzufügen`}
                                onActionButtonClick={() => { this.setState({ showCalendar: true }) }} />
                        </div>
                    </IonContent>
                </IonPage>

                <AddModal
                    isOpen={this.state.showCalendar}
                    showCloseButton={true}
                    type={(this.state.activeSegmentIndex === '0') ? 'product' : 'category'}
                    categoriesShortName={this.state.categoriesShortName}
                    categoriesLongName={this.state.categoriesLongName}
                    products={this.state.prods}
                    onCloseButtonPressed={() => { this.setState({ showCalendar: false }); this.refs['product-list'].closeSlidingItems(); }}
                    onDidDismiss={() => { this.setState({ showCalendar: false }); this.refs['product-list'].closeSlidingItems(); }}
                    onButtonPressed={(e) => { this._changeDatabase(e) }}>
                </AddModal>

                <IonToast
                    isOpen={this.state.showSuccessMessage}
                    onDidDismiss={() => { this.setState({ showSuccessMessage: false }); }}
                    message={this.state.successMessage}
                    position="top"
                    buttons={[
                        {
                            text: 'OK',
                            role: 'cancel',
                        }
                    ]} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        basicAuth: state.userinfos.basicAuth
    }
}

export default connect(mapStateToProps, null)(ProductDatabase);