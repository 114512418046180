import React from 'react';

import './ProductItem.css'

import { renderPrice } from '../Helper';

export default class ProductItem extends React.Component {
    render() {
        return (
            <>
                <div className={'productitem-main-container ' + ((this.props.rest) ? 'rest' : '')}>
                    <div className='productitem-border' style={{ border: `3px solid ${this.props.color}` }}>

                    </div>
                    <div className='productitem-content'>
                        <div className='productitem-headline'>
                            <div className='productitem-headline-name'>
                                {this.props.headline}
                            </div>
                        </div>
                        <div className='productitem-information'>
                            <div className='productitem-information-count'>
                                <span className={`${(this.props.highlightRevenue) ? '' : 'productitem-informatiom-active'}`} > {this.props.amount}</span>{(!this.props.rest) ? ('x ' + renderPrice(this.props.price, 'EUR')) : ''}
                            </div>
                            <div className='productitem-information-revenue'>
                                <span className={`${(this.props.highlightRevenue) ? 'productitem-informatiom-active' : ''}`}>{renderPrice(this.props.price * this.props.amount, 'EUR')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}