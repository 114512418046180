import React from 'react';
import { IonText } from '@ionic/react';

import Buttonbar from './Buttonbar';
import './ButtonbarMultiple.css';

export default class ButtonbarMultiple extends Buttonbar {
    _setActive(idx) {
        let copy = [...this.props.active];

        if (!copy.includes(idx)) {
            copy.push(idx);
        } else {
            //find element
            let index = copy.indexOf(idx);
            if (index !== -1) copy.splice(index, 1);
        }

        //collect values
        let values = [];
        for (let i = 0; i < copy.length; i++) {
            values.push(this.props.values[copy[i]]);
        }

        //return index array AND value array
        this.props.onChange(copy, values);
    }

    _renderOptions() {
        let ret = [];

        if (this.props.options && !this.props.hide) {
            this.props.options.forEach((option, index) => {
                if (this.props.active.includes(index)) {
                    ret.push(
                        <IonText key={index} onClick={() => { this._setActive(index) }} className='buttonbar-button buttonbar-multiple-button active'>
                            {option}
                        </IonText>
                    )
                } else {
                    ret.push(
                        <IonText key={index} onClick={() => { this._setActive(index) }} className='buttonbar-button buttonbar-multiple-button'>
                            {option}
                        </IonText>
                    );
                }
            });
        }

        return ret;
    }

    render() {
        return (
            <React.Fragment key={0}>
                <div className='buttonbar-button-container buttonbarmultiple-container'>
                    {this._renderOptions()}
                </div>
            </React.Fragment>
        )
    }
}