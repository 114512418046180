import { IonModal, IonText, IonDatetime, IonItem, IonLabel, IonList, IonIcon, IonContent, IonRippleEffect, IonButton } from "@ionic/react";
import { chevronUpCircleOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";

import CustomModal from "./CustomModal";

import "./NewCalendarModal.css";

export default class NewCalendarModal extends CustomModal {
    state = {
        showSuccessButton: false,
    };

    _getCurrentYear() {
        return new Date().getFullYear();
    }

    _getLastYear() {
        return new Date().getFullYear() - 1;
    }

    _renderDate(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    _technicalDate(date) {
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        return new Date(year, month, day, 0, 0, 0, 0).toISOString();
    }

    _renderDateRange(dateObject) {
        return this._renderDate(dateObject.start) + " bis " + this._renderDate(dateObject.end);
    }

    _getDate(range) {
        let start = new Date();
        let end = new Date();

        switch (range) {
            case "LAST_MONTH":
                start.setMonth(start.getMonth() - 1);
                start.setDate(1);
                end.setDate(1);
                end.setDate(end.getDate() - 1);
                break;
            case "CURRENT_MONTH":
                start.setDate(1);
                break;
            case "TODAY":
                //do nothing
                break;
            case "YESTERDAY":
                start.setDate(start.getDate() - 1);
                end.setDate(end.getDate() - 1);
                break;
            case "Q1_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(0);
                end.setMonth(2);
                end.setDate(31);
                break;
            case "Q2_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(3);
                end.setMonth(5);
                end.setDate(30);
                break;
            case "Q3_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(6);
                end.setMonth(8);
                end.setDate(30);
                break;
            case "Q4_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(9);
                end.setMonth(11);
                end.setDate(31);
                break;
            case "Q1_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(0);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(2);
                end.setDate(31);
                break;
            case "Q2_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(3);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(5);
                end.setDate(30);
                break;
            case "Q3_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(6);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(8);
                end.setDate(30);
                break;
            case "Q4_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(9);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(11);
                end.setDate(31);
                break;
            case "H1_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(0);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(6);
                end.setDate(30);
                break;
            case "H2_LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(7);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(11);
                end.setDate(31);
                break;
            case "H1_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(0);
                end.setMonth(6);
                end.setDate(30);
                break;
            case "H2_CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(7);
                end.setMonth(11);
                end.setDate(31);
                break;
            case "CURRENT_YEAR":
                start.setDate(1);
                start.setMonth(0);
                break;
            case "LAST_YEAR":
                start.setFullYear(start.getFullYear() - 1);
                start.setDate(1);
                start.setMonth(0);
                end.setFullYear(end.getFullYear() - 1);
                end.setMonth(11);
                end.setDate(31);
                break;
            default: //do nothing
        }

        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return {
            start: start,
            end: end,
        };
    }

    _setDate(range) {
        this.props.onChange(this._getDate(range));
        this.setState({ showSuccessButton: true });
    }

    _setSingleDate(start, date) {
        let d = new Date(date);

        if (start) {
            this.props.onChange({ start: d, end: this.props.endDate });
            this.setState({ showSuccessButton: true });
        } else {
            this.props.onChange({ start: this.props.startDate, end: d });
            this.setState({ showSuccessButton: true });
        }
    }

    render() {
        return (
            <>
                <IonModal className="custommodal-container" isOpen={this.props.isOpen} backdropDismiss={false}>
                    <div className="custommodal-header-container">
                        <div className="custommodal-closebutton-container">{this._renderCloseButton()}</div>
                        <div className="custommodal-headline-container">
                            <IonText className="custommodal-headline">Zeitraum festlegen</IonText>
                        </div>
                    </div>
                    <IonList className="newcalendarmodal-toplist">
                        <IonItem>
                            <IonLabel>Start</IonLabel>
                            <IonDatetime
                                displayFormat="D.M.YYYY"
                                min={moment().startOf('year').year('2016').format('YYYY-MM-DD')}
                                max={moment().endOf('year').format('YYYY-MM-DD')}
                                value={this._technicalDate(this.props.startDate)}
                                onIonChange={(e) => this._setSingleDate(true, e.detail.value)}
                            ></IonDatetime>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Ende</IonLabel>
                            <IonDatetime
                                displayFormat="D.M.YYYY"
                                min={moment().startOf('year').year('2016').format('YYYY-MM-DD')}
                                max={moment().endOf('year').format('YYYY-MM-DD')}
                                value={this._technicalDate(this.props.endDate)}
                                onIonChange={(e) => this._setSingleDate(false, e.detail.value)}
                            ></IonDatetime>
                        </IonItem>
                        {this.state.showSuccessButton ? (
                            <div className="newcalendar-button-container">
                                <IonButton
                                    onClick={() => {
                                        this.setState({ showSuccessButton: false });
                                        this.props.onSuccess();
                                    }}
                                    mode="ios"
                                    expand="full"
                                >
                                    neues Datum verwenden
                                </IonButton>
                            </div>
                        ) : null}
                    </IonList>

                    <IonContent>
                        <IonList className="newcalendarmodal-bottomlist">
                            <IonItem
                                onClick={() => {
                                    this._setDate("LAST_MONTH");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>letzter Monat</h2>
                                    <p>{this._renderDateRange(this._getDate("LAST_MONTH"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("CURRENT_MONTH");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>aktueller Monat</h2>
                                    <p>{this._renderDateRange(this._getDate("CURRENT_MONTH"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("TODAY");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Heute</h2>
                                    <p>{this._renderDateRange(this._getDate("TODAY"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                className="newcalendarmodal-item-space ion-activatable"
                                onClick={() => {
                                    this._setDate("YESTERDAY");
                                }}
                            >
                                <IonLabel>
                                    <h2>Gestern</h2>
                                    <p>{this._renderDateRange(this._getDate("YESTERDAY"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>dieses Jahr</h2>
                                    <p>{this._renderDateRange(this._getDate("CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                className="newcalendarmodal-item-space ion-activatable"
                                onClick={() => {
                                    this._setDate("LAST_YEAR");
                                }}
                            >
                                <IonLabel>
                                    <h2>letztes Jahr</h2>
                                    <p>{this._renderDateRange(this._getDate("LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("H1_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Halbjahr 1 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("H1_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("H2_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Halbjahr 2 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("H2_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("H1_LAST_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Halbjahr 1 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("H1_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                className="newcalendarmodal-item-space ion-activatable"
                                onClick={() => {
                                    this._setDate("H2_LAST_YEAR");
                                }}
                            >
                                <IonLabel>
                                    <h2>Halbjahr 2 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("H2_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q1_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 1 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q1_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q2_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 2 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q2_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q3_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 3 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q3_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q4_CURRENT_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 4 {this._getCurrentYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q4_CURRENT_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q1_LAST_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 1 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q1_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q2_LAST_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 2 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q2_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q3_LAST_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 3 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q3_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>

                            <IonItem
                                onClick={() => {
                                    this._setDate("Q4_LAST_YEAR");
                                }}
                                className="ion-activatable"
                            >
                                <IonLabel>
                                    <h2>Quartal 4 {this._getLastYear()}</h2>
                                    <p>{this._renderDateRange(this._getDate("Q4_LAST_YEAR"))}</p>
                                </IonLabel>
                                <IonIcon icon={chevronUpCircleOutline} slot="end" />
                                <IonRippleEffect></IonRippleEffect>
                            </IonItem>
                        </IonList>
                    </IonContent>
                </IonModal>
            </>
        );
    }
}
