import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import React from 'react';
import { withRouter } from "react-router";

import './SettingsMenu.css'

import { connect } from 'react-redux';
import {
    setUsername,
    setLoggedIn
} from '../redux/actions/useractions';

import Buttonbar from './Buttonbar';
import TextButton from './TextButton';

class SettingsMenu extends React.Component {
    state = {
        showDateSettings: false
    }

    _renderSegmentButtons() {
        let ret = [];

        if (this.props.segmentElements) {
            this.props.segmentElements.forEach((segment, index) => {
                ret.push(
                    <IonSegmentButton value={index} key={index}>
                        <IonLabel>
                            {segment}
                        </IonLabel>
                    </IonSegmentButton>
                )
            });
        }

        return ret;
    }

    render() {
        return (
            <>
                <div className='settingsmenu-container'>
                    <h2>Einstellungen</h2>
                    {(this.props.dateString) ?
                        <>
                            <h4 className='settingsmenu-h4'>Datum</h4>
                            <p className='settingsmenu-p settingsmenu-p-item'>
                                Zeitraum, in dem Daten erfasst wurden.
                            </p>

                            <p>{this.props.dateString}</p>

                            <TextButton onClick={() => { this.props.textButtonOnClick() }} title='Zeitraum anpassen' />
                        </>
                        : null}

                    {(this.props.actionButtonText) ?
                        <>
                            <h4 className='settingsmenu-h4'>Aktion</h4>
                            <p className='settingsmenu-p settingsmenu-p-item'>
                                Eine Aktion ausführen.
                            </p>

                            <p></p>

                            <TextButton onClick={() => { this.props.onActionButtonClick() }} title={this.props.actionButtonText} />
                        </>
                        : null}

                    {(this.props.segmentActiveIndex || this.props.segmentActiveIndex === 0) ?
                        <>
                            <h4 className='settingsmenu-h4'>Sortierung</h4>
                            <p className='settingsmenu-p'>
                                Sortiert angezeigte Daten nach Auswahl.
                            </p>
                            <IonSegment
                                mode='ios'
                                value={this.props.segmentActiveIndex}
                                onIonChange={(e) => { this.props.segmentOnChange(e.detail.value) }}>
                                {this._renderSegmentButtons()}
                            </IonSegment>
                        </>
                        : null}

                    {(this.props.buttonbarOptions) ?
                        <>
                            <h4 className='settingsmenu-h4'>Anzeige</h4>
                            <p className='settingsmenu-p'>
                                Ändert die Anzahl der angezeigten Daten.
                            </p>
                            <Buttonbar
                                options={this.props.buttonbarOptions}
                                values={this.props.buttonbarValues}
                                activeIndex={this.props.buttonbarActiveIndex}
                                onChange={(idx, value) => { this.props.buttonbarOnChange(idx, value) }} />
                        </>
                        : null}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.username
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUsername: (username) => { dispatch(setUsername(username)) },
        setLoggedIn: (loggedIn) => { dispatch(setLoggedIn(loggedIn)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsMenu));