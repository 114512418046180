import { IonImg } from '@ionic/react';
import React from 'react';

import './SideMenuItem.css'

export default class SideMenuItem extends React.Component {
    render() {
        return (
            <>
                <div onClick={this.props.onClick} className={`sidemenuitem ${(this.props.active) ? 'active' : ''}`}>
                    <div className='sidemenuitem-container'>
                        <div className='sidemenuitem-img-container'>
                            <IonImg
                                src={'../assets/' + this.props.imageName + ((this.props.active) ? '' : '_grey') + '.png'}
                                className='sidemenuitem-img'
                                alt={this.props.imageAlt} />
                        </div>
                        <p className={`${(this.props.important) ? 'bold' : ''}`}>
                            {this.props.title}
                        </p>
                    </div>
                </div>
            </>
        )
    }
}