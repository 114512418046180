import { IonModal, IonText, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import React from 'react';

import './CustomModal.css'

export default class CustomModal extends React.Component {
    _renderCloseButton() {
        if (this.props.showCloseButton) {
            return (
                <>
                    <IonIcon
                        onClick={() => { this.props.onCloseButtonPressed() }}
                        className='custommodal-closebutton-icon'
                        icon={closeOutline} />
                </>
            )
        } else {
            return null;
        }
    }

    render() {
        return (
            <>
                <IonModal
                    className='custommodal-container'
                    isOpen={this.props.isOpen}
                    onDidDismiss={() => { this.props.onDidDismiss() }}>
                    <div className='custommodal-header-container'>
                        <div className='custommodal-closebutton-container'>
                            {this._renderCloseButton()}
                        </div>
                        <div className='custommodal-headline-container'>
                            <IonText className='custommodal-headline'>{this.props.headline}</IonText>
                        </div>
                    </div>
                </IonModal>
            </>
        )
    }
}