import { IonIcon, IonImg } from "@ionic/react";
import { personCircleOutline } from "ionicons/icons";
import React from "react";
import { withRouter } from "react-router";

import "./SideMenu.css";

import { connect } from "react-redux";
import { setUsername, setLoggedIn, destroySession } from "../redux/actions/useractions";

import SideMenuItem from "./SideMenuItem";
import TextButton from "./TextButton";

class SideMenu extends React.Component {
    render() {
        return (
            <>
                <div className="sidemenu-container">
                    <IonImg className="sidemenu-logo" src="../../assets/sundairlogo.png" alt="Sundair Logo" />
                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/revenue");
                        }}
                        title="Umsatz"
                        imageName="money"
                        imageAlt="Money"
                        important={true}
                        active={this.props.location.pathname === "/revenue"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/products");
                        }}
                        title="Beste Produkte"
                        imageName="coffee"
                        imageAlt="Coffee"
                        important={true}
                        active={this.props.location.pathname === "/products"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/flights");
                        }}
                        title="Beste Flüge"
                        imageName="flight"
                        imageAlt="Flight"
                        important={true}
                        active={this.props.location.pathname === "/flights"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/crewmembers");
                        }}
                        title="Beste Crewmitglieder"
                        imageName="attendants"
                        imageAlt="Attendants"
                        important={true}
                        active={this.props.location.pathname === "/crewmembers"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/database");
                        }}
                        title="Produktdatenbank"
                        imageName="database"
                        imageAlt="Database"
                        important={false}
                        active={this.props.location.pathname === "/database"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/sales");
                        }}
                        title="Crewumsätze"
                        imageName="comission"
                        imageAlt="Comission"
                        important={false}
                        active={this.props.location.pathname === "/sales"}
                    />

                    <SideMenuItem
                        onClick={() => {
                            this.props.history.push("/settings");
                        }}
                        title="Einstellungen"
                        imageName="settings"
                        imageAlt="Settings"
                        important={false}
                        active={this.props.location.pathname.startsWith("/settings")}
                    />

                    <div className="sidemenu-userinfo-container">
                        <IonIcon className="sidemenu-userinfo-icon" icon={personCircleOutline} />
                        <p className="sidemenu-userinfo">{this.props.username}</p>
                        <p>1.0.4 (111)</p>
                        <TextButton
                            onClick={() => {
                                this.props.destroySession();
                                this.props.history.push("/login");
                            }}
                            title="abmelden"
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.userinfos.username,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsername: (username) => {
            dispatch(setUsername(username));
        },
        setLoggedIn: (loggedIn) => {
            dispatch(setLoggedIn(loggedIn));
        },
        destroySession: () => {
            dispatch(destroySession());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));
