import { IonContent, IonPage, IonIcon, IonList, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';

import './Settings.css';

import Topbar from '../js/components/Topbar';

import SideMenu from '../js/components/SideMenu';
import '../overall.css'

import { connect } from 'react-redux';


class Settings extends React.Component {
    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent forceOverscroll={true} fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue='Einstellungen' />
                            <IonList>
                                <IonItem disabled>
                                    <IonLabel>
                                        Dark Mode
                                    </IonLabel>
                                    <IonToggle checked={this.props.settings.darkMode} mode='ios'></IonToggle>
                                </IonItem>
                                <IonItem disabled className='settings-item' onClick={() => { this.props.history.push('/settings/dates') }}>
                                    <IonLabel>
                                        Vordefinierte Daten
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/values') }}>
                                    <IonLabel>
                                        Standardwerte
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                                <IonItem className='settings-item' onClick={() => { this.props.history.push('/settings/info') }}>
                                    <IonLabel>
                                        App Informationen
                            </IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" />
                                </IonItem>
                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings
    }
}

export default connect(mapStateToProps, null)(Settings);