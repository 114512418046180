import { IonContent, IonPage, IonList, IonItem, IonLabel, IonNote } from '@ionic/react';
import React from 'react';
//import './SettingsDates.css';

import Topbar from '../../js/components/Topbar';

import SideMenu from '../../js/components/SideMenu';
import '../../overall.css'

export default class SettingsInfo extends React.Component {

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue='App Information' />
                            <IonList>
                                <IonItem>
                                    <IonLabel>
                                        Version
                                    </IonLabel>
                                    <IonNote slot='end'>
                                        0.5
                                    </IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Prüfsumme
                                    </IonLabel>
                                    <IonNote slot='end'>
                                        0a7f01e8
                                    </IonNote>
                                </IonItem>
                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}
