import { IonContent, IonPage, IonLoading } from "@ionic/react";
import React from "react";

import "./Revenue.css";

import Topbar from "../js/components/Topbar";
import NewCalendarModal from "../js/components/NewCalendarModal";
import RevenueCard from "../js/components/RevenueCard";

import SideMenu from "../js/components/SideMenu";
import SettingsMenu from "../js/components/SettingsMenu";
import "../overall.css";

import API from "../API";

import { connect } from "react-redux";

class Revenue extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        currentCalendarStartDate: new Date(),
        currentCalendarEndDate: new Date(),

        showLoadingScreen: false,
        activeSegmentIndex: this.props.settings.standardRevenueSegment,

        revenue: {},
        numberOfOrders: {},
        barData: [],
        dayData: [],
        monthData: [],
        lastUpdate: new Date(2000, 1, 1).getTime(),

        breakdown: null
    };

    async componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.push({ pathname: "/login", state: { detail: window.location.href.replace("http://localhost:8100", "") } });
        }

        //begin of the current month
        let begin = new Date();
        begin.setDate(1);
        begin.setMonth(begin.getMonth());

        //today
        let end = new Date();

        this.setState({ startDate: begin, endDate: end });

        await this._updateData(begin, end);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== "/revenue" &&
            this.props.location.pathname.startsWith("/revenue")
        ) {
            this._updateData(this.state.startDate, this.state.endDate);
        }
    }

    _roundThounsand(value) {
        return Math.round(value / 1000) * 1000;
    }

    _getDayName(day) {
        if (day === 0) {
            return "SO";
        } else if (day === 1) {
            return "MO";
        } else if (day === 2) {
            return "DI";
        } else if (day === 3) {
            return "MI";
        } else if (day === 4) {
            return "DO";
        } else if (day === 5) {
            return "FR";
        } else {
            return "SA";
        }
    }

    _getMonthName(month) {
        if (month === 0) {
            return "JAN";
        } else if (month === 1) {
            return "FEB";
        } else if (month === 2) {
            return "MÄR";
        } else if (month === 3) {
            return "APR";
        } else if (month === 4) {
            return "MAI";
        } else if (month === 5) {
            return "JUN";
        } else if (month === 6) {
            return "JUL";
        } else if (month === 7) {
            return "AUG";
        } else if (month === 8) {
            return "SEP";
        } else if (month === 9) {
            return "OKT";
        } else if (month === 10) {
            return "NOV";
        } else if (month === 11) {
            return "DEZ";
        }
    }

    _createLabel(revenue) {
        if (revenue === 0) {
            return "0";
        } else if (revenue < 1000) {
            return Math.round(revenue).toString();
        } else if (revenue < 10000) {
            return revenue.toString().substring(0, 1) + (revenue.toString().substring(1, 2) ? "," + revenue.toString().substring(1, 2) : "") + "k";
        } else if (revenue < 100000) {
            return revenue.toString().substring(0, 2) + "k";
        } else if (revenue < 1000000) {
            return revenue.toString().substring(0, 3) + "k";
        } else if (revenue < 10000000) {
            return revenue.toString().substring(0, 1) + (revenue.toString().substring(1, 2) ? "," + revenue.toString().substring(1, 2) : "") + "M";
        } else if (revenue < 100000000) {
            return revenue.toString().substring(0, 2) + "M";
        } else if (revenue < 1000000000) {
            return revenue.toString().substring(0, 3) + "M";
        } else {
            return "";
        }
    }

    _getBarData(type) {
        let barData = [];
        let barLabel = [];
        let barSublabel = [];

        if (type === "DAY") {
            for (let i = 0; i < this.state.dayData.length; i++) {
                if (this.state.activeSegmentIndex === "0") {
                    barData.push(this.state.dayData[i].revenue.cc.total);

                    let label = this._createLabel(this.state.dayData[i].revenue.cc.total);
                    barSublabel.push(label);
                } else if (this.state.activeSegmentIndex === "1") {
                    barData.push(this.state.dayData[i].revenue.cash.total);

                    let label = this._createLabel(this.state.dayData[i].revenue.cash.total);
                    barSublabel.push(label);
                } else {
                    barData.push(this.state.dayData[i].revenue.total.total);

                    let label = this._createLabel(this.state.dayData[i].revenue.total.total);
                    barSublabel.push(label);
                }

                barLabel.push(this._getDayName(this.state.dayData[i].date.getDay()));
            }
        } else if (type === "MONTH") {
            for (let i = 0; i < this.state.monthData.length; i++) {
                if (this.state.activeSegmentIndex === "0") {
                    barData.push(this.state.monthData[i].revenue.cc.total);

                    let label = this._createLabel(this.state.monthData[i].revenue.cc.total);
                    barSublabel.push(label);
                } else if (this.state.activeSegmentIndex === "1") {
                    barData.push(this.state.monthData[i].revenue.cash.total);

                    let label = this._createLabel(this.state.monthData[i].revenue.cash.total);
                    barSublabel.push(label);
                } else {
                    barData.push(this.state.monthData[i].revenue.total.total);

                    let label = this._createLabel(this.state.monthData[i].revenue.total.total);
                    barSublabel.push(label);
                }

                barLabel.push(this._getMonthName(this.state.monthData[i].date.getMonth()));
            }
        }

        return [barData, barLabel, barSublabel];
    }

    async _loadNewData(startDate, endDate, type) {
        //get the user wanted data
        let revenueDate = await new API().getRevenue(startDate, endDate, this.props.basicAuth);

        let revenue = {};

        let cashTotal = 0;
        let cashOrders = 0;
        let ccTotal = 0;
        let ccOrders = 0;

        if (revenueDate.length > 0) {
            if (type === 1) {
                this.setState({ breakdown: revenueDate[0].revenueBreakdown });
            }


            for (let c in revenueDate[0].revenueBreakdown) {
                let act = revenueDate[0].revenueBreakdown[c];

                cashTotal += act.CS_revenue;
                cashOrders += act.CS_number;
                ccTotal += act.CC_revenue;
                ccOrders += act.CC_number;
            }

            revenue.total = {
                total: revenueDate[0].totalRevenueEUR,
                orders: revenueDate[0].totalSold,
            };

            revenue.cash = {
                total: cashTotal,
                orders: cashOrders,
            };

            revenue.cc = {
                total: ccTotal,
                orders: ccOrders,
            };
        } else {
            revenue.total = {
                total: 0,
                orders: 0,
            };

            revenue.cash = {
                total: 0,
                orders: 0,
            };

            revenue.cc = {
                total: 0,
                orders: 0,
            };
        }

        return revenue;
    }

    async _updateData(startDate, endDate) {
        if (!this.props.loggedIn) {
            return;
        }

        this.setState({ showLoadingScreen: true });

        //update the curren trevenue stats
        let revenue = await this._loadNewData(startDate, endDate, 1);
        this.setState({ revenue: revenue });

        //update the numberOfOrders
        let numberOfOrders = await new API().getNumberOfOrders(startDate, endDate, this.props.basicAuth);
        this.setState({ numberOfOrders: numberOfOrders });

        //update every 30 minutes
        //i think this fits
        if (Date.now() - this.state.lastUpdate > 1800000) {
            this.setState({ lastUpdate: Date.now() });
            let dayData = [];

            //get revenue data from the last 7 days
            let todayMorning = new Date();
            todayMorning.setHours(0);
            todayMorning.setMinutes(0);
            todayMorning.setSeconds(0);

            let todayEvening = new Date();
            todayEvening.setHours(23);
            todayEvening.setMinutes(59);
            todayEvening.setSeconds(59);

            for (let i = 0; i < 8; i++) {
                let revenue = await this._loadNewData(todayMorning, todayEvening, 0);

                dayData.unshift({
                    date: new Date(todayMorning),
                    revenue: revenue,
                });

                todayEvening.setDate(todayEvening.getDate() - 1);
                todayMorning.setDate(todayMorning.getDate() - 1);
            }

            this.setState({ dayData: dayData });

            let monthData = [];

            //get revenue data from the last 7 months
            let thisMonthStart = new Date();
            thisMonthStart.setDate(1);
            thisMonthStart.setHours(0);
            thisMonthStart.setMinutes(0);
            thisMonthStart.setSeconds(0);

            let thisMonthEnd = new Date();
            thisMonthEnd.setMonth(thisMonthStart.getMonth() + 1);
            thisMonthEnd.setDate(0);
            thisMonthEnd.setHours(23);
            thisMonthEnd.setMinutes(59);
            thisMonthEnd.setSeconds(59);

            for (let i = 0; i < 13; i++) {
                let revenue = await this._loadNewData(thisMonthStart, thisMonthEnd, 0);

                monthData.unshift({
                    date: new Date(thisMonthStart),
                    revenue: revenue,
                });

                thisMonthStart.setMonth(thisMonthStart.getMonth() - 1);
                thisMonthEnd.setFullYear(thisMonthStart.getFullYear());
                thisMonthEnd.setMonth(thisMonthStart.getMonth() + 1);
                thisMonthEnd.setDate(0);
            }

            this.setState({ monthData: monthData });
        }

        this.setState({ showLoadingScreen: false });
    }

    _getURLParamterFromDate(date) {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    _createShareLink() {
        let ret = "";

        ret += window.location.href.replace(window.location.pathname, "");
        ret += "/revenue?";
        ret += "start=" + this._getURLParamterFromDate(this.state.startDate) + "&";
        ret += "end=" + this._getURLParamterFromDate(this.state.endDate) + "&";
        ret += "seg=" + this.state.activeSegmentIndex;

        return ret;
    }

    _closeButtonPressed() {
        //nothing to do here
        //i hope so
    }

    _calendarChange(value) {
        this.setState({ currentCalendarStartDate: value.start });
        this.setState({ currentCalendarEndDate: value.end });
    }

    _setNewDates() {
        this.setState({ startDate: this.state.currentCalendarStartDate, endDate: this.state.currentCalendarEndDate }, () => {
            this.setState({ showCalendar: false });

            this._updateData(this.state.startDate, this.state.endDate);
        });
    }

    _openCalendar() {
        this.setState({ currentCalendarEndDate: this.state.endDate, currentCalendarStartDate: this.state.startDate }, () => {
            this.setState({ showCalendar: true });
        });
    }

    _changeSegmentFilter(index) {
        //0 = revenue
        //1 = number
        this.setState({ activeSegmentIndex: index });
    }

    render() {
        return (
            <IonPage>
                <IonLoading
                    isOpen={this.state.showLoadingScreen}
                    onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                    cssClass="spinner"
                    spinner={null}
                />
                <IonContent forceOverscroll={true} fullscreen class="main-container">
                    <div className="flex-main-container">
                        <SideMenu />
                        <div className="flex-content-container">
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue="Umsatz"
                                showTextButton={true}
                                textButtonValue={`${this.state.startDate && this.state.endDate
                                    ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                    " - " +
                                    this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                    : ""
                                    }`}
                                textButtonIconStyle="calendar"
                                onTextButtonClick={() => {
                                    this._openCalendar();
                                }}
                                showSegment={true}
                                segmentElements={["Kreditkarte", "Bar", "Gesamt"]}
                                activeSegmentIndex={this.state.activeSegmentIndex}
                                onSegmentChange={(idx) => {
                                    this._changeSegmentFilter(idx);
                                }}
                            />

                            <div className="revenue-container">
                                <RevenueCard
                                    title={`${this.state.startDate && this.state.endDate
                                        ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                        " - " +
                                        this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                        : ""
                                        }`}
                                    subtitle={
                                        this.state.activeSegmentIndex === "0" ? "Kreditkarte" : this.state.activeSegmentIndex === "1" ? "Bar" : "Gesamtumsatz"
                                    }
                                    revenue={
                                        this.state.activeSegmentIndex === "0"
                                            ? this.state.revenue.cc
                                            : this.state.activeSegmentIndex === "1"
                                                ? this.state.revenue.cash
                                                : this.state.revenue.total
                                    }
                                    numberOfOrders={
                                        this.state.activeSegmentIndex === "0"
                                            ? this.state.numberOfOrders.creditcard
                                            : this.state.activeSegmentIndex === "1"
                                                ? this.state.numberOfOrders.cash
                                                : this.state.numberOfOrders.total
                                    }
                                    breakdown={this.state.breakdown}
                                    revenueType={this.state.activeSegmentIndex}
                                />

                                <RevenueCard
                                    title="UMSATZ GESTERN"
                                    calculationType="last"
                                    subtitle={
                                        this.state.activeSegmentIndex === "0" ? "Kreditkarte" : this.state.activeSegmentIndex === "1" ? "Bar" : "Gesamtumsatz"
                                    }
                                    data={this._getBarData("DAY")}
                                />

                                <RevenueCard
                                    title="DURCHSCHNITTLICHER UMSATZ PRO MONAT"
                                    calculationType="average"
                                    subtitle={
                                        this.state.activeSegmentIndex === "0" ? "Kreditkarte" : this.state.activeSegmentIndex === "1" ? "Bar" : "Gesamtumsatz"
                                    }
                                    data={this._getBarData("MONTH")}
                                />
                            </div>

                            <NewCalendarModal
                                isOpen={this.state.showCalendar}
                                showCloseButton={true}
                                onCloseButtonPressed={() => {
                                    this.setState({ showCalendar: false });
                                }}
                                startDate={this.state.currentCalendarStartDate}
                                endDate={this.state.currentCalendarEndDate}
                                onChange={(v) => {
                                    this._calendarChange(v);
                                }}
                                onSuccess={() => {
                                    this._setNewDates();
                                }}
                            />
                        </div>
                        <SettingsMenu
                            textButtonOnClick={() => {
                                this._openCalendar();
                            }}
                            dateString={`${this.state.startDate && this.state.endDate
                                ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                " - " +
                                this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                : ""
                                }`}
                            buttonbarActiveIndex={this.state.optionButtonbar}
                            buttonbarOnChange={(idx, value) => {
                                this._changeTopFilter(idx, value);
                            }}
                            segmentElements={["Kreditkarte", "Bar", "Gesamt"]}
                            segmentActiveIndex={this.state.activeSegmentIndex}
                            url={this._createShareLink()}
                            segmentOnChange={(idx) => {
                                this._changeSegmentFilter(idx);
                            }}
                        />
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings,
        basicAuth: state.userinfos.basicAuth,

        username: state.userinfos.username,
    };
};

export default connect(mapStateToProps, null)(Revenue);
