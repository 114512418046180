import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
//import './SettingsDates.css';

import Topbar from '../../js/components/Topbar';

export default class SettingsDates extends React.Component {

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <Topbar
                        showBackButton={true}
                        showMenuButton={true}
                        headlineValue='Vordefinierte Daten' />
                </IonContent>
            </IonPage >
        );
    }
}
