import React from "react";

import "./RevenueCard.css";

import { renderPrice } from "../Helper";

export default class RevenueCard extends React.Component {
    _map(value, a, b, c, d) {
        value = (value - a) / (b - a);
        return c + value * (d - c);
    }

    _calculateAverage() {
        if (this.props.data) {
            const barData = this.props.data[0];
            let sum = 0;

            for (let i = 0; i < barData.length - 1; i++) {
                sum += barData[i];
            }

            return sum / (this.props.data[0].length - 1);
        }
    }

    _createBarLabels(bardata) {
        let ret = [];

        for (let i = 0; i < bardata.length; i++) {
            ret.push(bardata[i].toFixed(2));
        }

        return ret;
    }

    _renderBars() {
        if (this.props.data) {
            const barData = this.props.data[0];
            const barLabel = this.props.data[1];
            //const barSublabel = this.props.data[2];
            const barSublabel = this._createBarLabels(this.props.data[0]);
            let bars = [];

            let max = barData[0];
            let min = barData[0];
            for (let i = 0; i < barData.length; i++) {
                if (max < barData[i]) {
                    max = barData[i];
                }

                if (min > barData[i]) {
                    min = barData[i];
                }
            }

            for (let i = 0; i < barData.length; i++) {
                let value = barData[i];
                let label = barLabel[i];
                let sublabel = barSublabel[i];

                //map the revenues from 2rem to 7rem
                //2rem is the lowest revenue
                //7rem is the highest revenue
                value = this._map(value, min, max, 2, 7);

                bars.push(
                    <div key={i} className="revenue-card-chart-bar-container">
                        <div className="revenue-card-chart-bar">
                            <div className={`revenue-card-chart-bar-inner ${i + 1 === barData.length ? "active" : ""}`} style={{ height: `${value}rem` }}></div>
                        </div>
                        <div className="revenue-card-chart-sublabel">
                            <p>
                                {sublabel.split(".")[0]}
                                <span style={{ fontSize: "0.8rem" }}>.{sublabel.split(".")[1] ?? ".00"}</span>
                            </p>
                        </div>
                        <div className={`revenue-card-chart-label ${i + 1 === barData.length ? "active" : ""}`}>
                            <p>{label}</p>
                        </div>
                    </div>
                );
            }

            return bars;
        }
    }

    componentDidUpdate() {
        if (this.refs["barView"]) {
            this.refs["barView"].scrollLeft += 1000;
        }

    }

    _calculateRevenuePerOrder(orders, total) {
        return total / orders;
    }

    _getLastEntry() {
        let revenues = this.props.data[0];

        if (this.props.data) {
            return revenues[revenues.length - 2];
        } else {
            return 0;
        }
    }

    render() {
        return (
            <>
                <div className="revenue-card">
                    <div className="revenue-card-title">
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className="revenue-card-subtitle">
                        <h3>{this.props.subtitle}</h3>
                    </div>
                    {this.props.data ? (
                        <div className="revenue-card-value">
                            {/*<h2>{renderPrice(this._calculateAverage(), 'EUR')}</h2>*/}
                            {this.props.calculationType === "last" ? <h2>{renderPrice(this._getLastEntry(), "EUR")}</h2> : null}
                            {this.props.calculationType === "average" ? <h2>{renderPrice(this._calculateAverage(), "EUR")}</h2> : null}
                        </div>
                    ) : null}

                    {this.props.revenue ? (
                        <>
                            <div className="revenue-card-value">
                                {/* revenueType = 0: CC, 1: CS, 2: TOTAL */}
                                {this.props.revenueType === "0" ?
                                    <h2>
                                        {this.props.breakdown && this.props.breakdown.EUR ? renderPrice(this.props.breakdown.EUR.CC_revenue, "EUR") : renderPrice(0, "EUR")} / {" "}
                                        {this.props.breakdown && this.props.breakdown.USD ? renderPrice(this.props.breakdown.USD.CC_revenue, "USD") : renderPrice(0, "USD")}
                                    </h2> : null}
                                {this.props.revenueType === "1" ?
                                    <h2>
                                        {this.props.breakdown && this.props.breakdown.EUR ? renderPrice(this.props.breakdown.EUR.CS_revenue, "EUR") : renderPrice(0, "EUR")} / {" "}
                                        {this.props.breakdown && this.props.breakdown.USD ? renderPrice(this.props.breakdown.USD.CS_revenue, "USD") : renderPrice(0, "USD")}
                                    </h2> : null}
                                {this.props.revenueType === "2" ?
                                    <h2>
                                        {this.props.breakdown && this.props.breakdown.EUR ? renderPrice(this.props.breakdown.EUR.CC_revenue + this.props.breakdown.EUR.CS_revenue, "EUR") : renderPrice(0, "EUR")} / {" "}
                                        {this.props.breakdown && this.props.breakdown.USD ? renderPrice(this.props.breakdown.USD.CC_revenue + this.props.breakdown.USD.CS_revenue, "USD") : renderPrice(0, "USD")}
                                    </h2> : null}
                            </div>

                            <div className="revenue-subcard-container">
                                <div className="revenue-subcard">
                                    <div className="revenue-subcard-value">
                                        <h3>{this.props.revenue.orders}</h3>
                                    </div>
                                    <div className="revenue-subcard-title">
                                        <p>verkaufte Produkte</p>
                                    </div>
                                </div>
                                <div className="revenue-subcard">
                                    <div className="revenue-subcard-value">
                                        <h3>{this.props.numberOfOrders}</h3>
                                    </div>
                                    <div className="revenue-subcard-title">
                                        <p>Bestellungen</p>
                                    </div>
                                </div>
                                {this.props.numberOfOrders ? (
                                    <>
                                        <div className="revenue-subcard">
                                            <div className="revenue-subcard-value">
                                                <h3>
                                                    {renderPrice(this._calculateRevenuePerOrder(this.props.numberOfOrders, this.props.revenue.total), "EUR")}
                                                </h3>
                                            </div>
                                            <div className="revenue-subcard-title">
                                                <p>durchsch. Umsatz pro Bestellungen</p>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : null}

                    <div className="revenue-card-chart-container" ref="barView">
                        {this._renderBars()}
                    </div>
                </div>
            </>
        );
    }
}
