import { IonContent, IonPage, IonLoading, IonInput, IonItem, IonLabel, IonText, IonButton } from "@ionic/react";
import React from "react";

import "./Flights.css";

import Topbar from "../js/components/Topbar";

import Buttonbar from "../js/components/Buttonbar";
import BarItem from "../js/components/BarItem";
import NewCalendarModal from "../js/components/NewCalendarModal";
import ErrorMessage from "../js/components/ErrorMessage";

import SideMenu from "../js/components/SideMenu";
import SettingsMenu from "../js/components/SettingsMenu";
import "../overall.css";

import API from "../API";
import { renderPrice } from "../js/Helper";

import { connect } from "react-redux";

class Flight extends React.Component {
    state = {
        optionSegment: this.props.settings.standardFlightSegment,
        optionButtonbar: this.props.settings.standardFlightTopIndex,
        valueButtonbar: this.props.settings.standardFlightTopValue,
        showLoadingScreen: false,

        presentationArray: [],
        flights: [],

        startDate: new Date(),
        endDate: new Date(),
        currentCalendarStartDate: new Date(),
        currentCalendarEndDate: new Date(),

        airportFromIataCode: null,
        airportToIataCode: null,
        flightNo: null,
        showAirportInputs: false,
        flightNoString: "alle",
    };

    setFlightNoString(flightNoString) {
        this.setState({ flightNoString: flightNoString });
    }

    setAirportFrom(iataCode) {
        let newIataCode = null;

        if (iataCode) {
            newIataCode = iataCode.toUpperCase();
        }

        this.setState({ airportFromIataCode: newIataCode });
    }

    setAirportTo(iataCode) {
        let newIataCode = null;

        if (iataCode) {
            newIataCode = iataCode.toUpperCase();
        }

        this.setState({ airportToIataCode: newIataCode });
    }

    async componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.push({ pathname: "/login", state: { detail: window.location.href.replace("http://localhost:8100", "") } });
        }

        //begin of the current month
        let begin = new Date();
        begin.setDate(1);
        begin.setMonth(begin.getMonth());

        //today
        let end = new Date();

        this.setState({ startDate: begin, endDate: end });

        await this._loadNewData(begin, end);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== "/flights" &&
            this.props.location.pathname.startsWith("/flights")
        ) {
            this._loadNewData(this.state.startDate, this.state.endDate);
        }
    }

    async _loadNewData(startDate, endDate) {
        this.setState({ showLoadingScreen: true });

        //get the user wanted data
        let flights = await new API().getBestFlights(startDate, endDate, this.state.flightNo, this.props.basicAuth);

        //sort by users segment selection
        //0 = totalRevenueEUR
        //1 = totalSold

        if (this.state.optionSegment === "0") {
            flights.sort((a, b) => {
                return a.totalRevenueEUR < b.totalRevenueEUR ? 1 : -1;
            });
        } else {
            flights.sort((a, b) => {
                return a.totalSold < b.totalSold ? 1 : -1;
            });
        }

        //cut out the relevant part
        //filter options and some data for the pie chart
        let presentationArray = this._getPresentationArrays(flights);

        this.setState({ flights: flights, presentationArray: presentationArray });

        this.setState({ showLoadingScreen: false });
    }

    _getURLParamterFromDate(date) {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    _createShareLink() {
        let ret = "";

        ret += ret += window.location.href.replace(window.location.pathname, "");
        ret += "/flights?";
        ret += "start=" + this._getURLParamterFromDate(this.state.startDate) + "&";
        ret += "end=" + this._getURLParamterFromDate(this.state.endDate) + "&";
        ret += "seg=" + this.state.activeSegmentIndex + "&";
        ret += "top=" + this.state.optionButtonbar;

        return ret;
    }

    _renderFlightItems() {
        let ret = [];

        //calculate the percentage of every item
        //flights is ordered by revenue
        let max = 0;
        if (this.state.presentationArray.length > 0) {
            if (this.state.optionSegment === "0") {
                max = this.state.presentationArray[0].totalRevenueEUR;
            } else {
                max = this.state.presentationArray[0].totalSold;
            }
        }

        for (let i = 0; i < this.state.presentationArray.length; i++) {
            let act = this.state.presentationArray[i];

            //if optionSegment == 0 show revenue
            //else (== 1) show sold
            if (this.state.optionSegment === "0") {
                ret.push(
                    <BarItem
                        key={i}
                        title={`${act.flightLeg}`}
                        hint="revenue"
                        revenue={`${renderPrice(act.totalRevenueEUR, "EUR")}`}
                        number={`${act.totalSold}`}
                        percent={Math.floor((act.totalRevenueEUR * 100) / max)}
                    />
                );
            } else {
                ret.push(
                    <BarItem
                        key={i}
                        title={`${act.flightLeg}`}
                        hint="number"
                        revenue={`${renderPrice(act.totalRevenueEUR, "EUR")}`}
                        number={`${act.totalSold}`}
                        percent={Math.floor((act.totalSold * 100) / max)}
                    />
                );
            }
        }

        //check if is data to show
        if (ret.length === 0) {
            ret.push(<ErrorMessage key={0} />);
        }

        return ret;
    }

    _getPresentationArrays(flights) {
        //get the first relevant options
        let relevantFlights = flights.slice(0, this.state.valueButtonbar);

        return relevantFlights;
    }

    _changeTopFilter(index, value) {
        //update state
        this.setState({ optionButtonbar: index, valueButtonbar: value }, () => {
            //update the displayed products
            //filter options and some data for the pie chart
            let presentationArray = this._getPresentationArrays(this.state.flights);

            this.setState({ presentationArray: presentationArray });
        });
    }

    _changeSegmentFilter(index) {
        //0 = revenue
        //1 = number
        this.setState({ optionSegment: index });

        let copy = [...this.state.flights];
        //sort products by revenue/number
        if (index === "0") {
            copy.sort((a, b) => {
                return a.totalRevenueEUR < b.totalRevenueEUR ? 1 : -1;
            });
        } else {
            copy.sort((a, b) => {
                return a.totalSold < b.totalSold ? 1 : -1;
            });
        }

        this.setState({ flights: copy }, () => {
            //update the displayed products
            //filter options and some data for the pie chart
            let presentationArray = this._getPresentationArrays(this.state.flights);

            this.setState({ presentationArray: presentationArray });
        });
    }

    _calendarChange(value) {
        this.setState({ currentCalendarStartDate: value.start });
        this.setState({ currentCalendarEndDate: value.end });
    }

    _setNewDates() {
        this.setState({ startDate: this.state.currentCalendarStartDate, endDate: this.state.currentCalendarEndDate }, () => {
            this.setState({ showCalendar: false });

            this._loadNewData(this.state.startDate, this.state.endDate);
        });
    }

    _openCalendar() {
        this.setState({ currentCalendarEndDate: this.state.endDate, currentCalendarStartDate: this.state.startDate }, () => {
            this.setState({ showCalendar: true });
        });
    }

    _toggleAirportInputs() {
        this.setState({ showAirportInputs: !this.state.showAirportInputs });
    }

    _searchFlightsClicked() {
        this._loadNewData(this.state.startDate, this.state.endDate);
        this.setState({ showAirportInputs: false });
        if (this.state.flightNo) {
            this.setState({ flightNoString: this.state.flightNo });
        } else {
            this.setState({ flightNoString: "alle" });
        }
    }


    _setFlightNo(flightNo) {
        this.setState({flightNo: flightNo})
    }

    render() {
        return (
            <IonPage>
                <IonLoading
                    isOpen={this.state.showLoadingScreen}
                    cssClass="spinner"
                    spinner={null}
                    onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                />
                <IonContent fullscreen class="main-container">
                    <div className="flex-main-container">
                        <SideMenu />
                        <div className="flex-content-container">
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue="Beste Flugverbindungen"
                                showTextButton={true}
                                textButtonValue={`${
                                    this.state.startDate && this.state.endDate
                                        ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                          " - " +
                                          this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                        : ""
                                }`}
                                textButtonIconStyle="calendar"
                                onTextButtonClick={() => {
                                    this._openCalendar();
                                }}
                                showSegment={true}
                                segmentElements={["Umsatz", "Anzahl"]}
                                activeSegmentIndex={this.state.optionSegment}
                                onSegmentChange={(idx) => {
                                    this.setState({ optionSegment: idx });
                                }}
                            />

                            <div className="buttonbar-container">
                                <Buttonbar
                                    options={["Top 3", "Top 5", "Top 10", "alle"]}
                                    values={[3, 5, 10, 10000]}
                                    activeIndex={this.state.optionButtonbar}
                                    onChange={(idx, value) => {
                                        this._changeTopFilter(idx, value);
                                    }}
                                />
                            </div>

                            <IonText onClick={() => this._toggleAirportInputs()}>
                                <p className="ion-margin-start" style={{ color: "var(--ion-color-primary)", textDecoration: "underline", cursor: "pointer" }}>
                                    angezeigte Flüge: {this.state.flightNoString}
                                </p>
                            </IonText>
                            <div className={`${this.state.showAirportInputs ? "" : "ion-hide"}`}>
                                <IonItem>
                                    <IonLabel position="floating">Startflughafen</IonLabel>
                                    <IonInput
                                        onIonChange={(e) => this._setFlightNo(e.detail.value)}
                                        value={this.state.flightNo}
                                        inputmode="text"
                                        autocapitalize="characters"
                                    ></IonInput>
                                </IonItem>
                                <IonButton mode="ios" onClick={() => this._searchFlightsClicked()}>
                                    Ansicht aktualisieren
                                </IonButton>
                            </div>

                            <div className="bar-container">{this._renderFlightItems()}</div>

                            <NewCalendarModal
                                isOpen={this.state.showCalendar}
                                showCloseButton={true}
                                onCloseButtonPressed={() => {
                                    this.setState({ showCalendar: false });
                                }}
                                startDate={this.state.currentCalendarStartDate}
                                endDate={this.state.currentCalendarEndDate}
                                onChange={(v) => {
                                    this._calendarChange(v);
                                }}
                                onSuccess={() => {
                                    this._setNewDates();
                                }}
                            />
                        </div>
                        <SettingsMenu
                            textButtonOnClick={() => {
                                this._openCalendar();
                            }}
                            dateString={`${
                                this.state.startDate && this.state.endDate
                                    ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                      " - " +
                                      this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                    : ""
                            }`}
                            buttonbarOptions={["Top 3", "Top 5", "Top 10", "alle"]}
                            buttonbarValues={[3, 5, 10, 10000]}
                            buttonbarActiveIndex={this.state.optionButtonbar}
                            buttonbarOnChange={(idx, value) => {
                                this._changeTopFilter(idx, value);
                            }}
                            segmentElements={["Umsatz", "Anzahl"]}
                            segmentActiveIndex={this.state.optionSegment}
                            url={this._createShareLink()}
                            segmentOnChange={(idx) => {
                                this._changeSegmentFilter(idx);
                            }}
                        />
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        basicAuth: state.userinfos.basicAuth,
        settings: state.settings,
    };
};

export default connect(mapStateToProps, null)(Flight);
