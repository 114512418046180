import { IonIcon, IonImg, IonSearchbar, IonSegment, IonSegmentButton, IonLabel, IonPopover, IonText, IonList, IonItem } from '@ionic/react';
import { arrowBackOutline, ellipsisVertical, logOutOutline, chevronForwardOutline } from 'ionicons/icons';
import React from 'react';
import { withRouter } from "react-router";

import './Topbar.css'

import TextButton from './TextButton';

import { connect } from 'react-redux';
import {
    setUsername,
    setLoggedIn
} from '../redux/actions/useractions';

class Topbar extends React.Component {
    state = {
        showMenu: false,
    }

    _renderSegmentButtons() {
        let ret = [];

        if (this.props.showSegment && this.props.segmentElements) {
            this.props.segmentElements.forEach((segment, index) => {
                ret.push(
                    <IonSegmentButton value={index} key={index}>
                        <IonLabel>
                            {segment}
                        </IonLabel>
                    </IonSegmentButton>
                )
            });
        }

        return ret;
    }

    _backButtonPressed() {
        //if on mobile, always go back to deshboard
        //otherwise just go back
        if (window.innerWidth < 900 && !this.props.history.location.pathname.startsWith('/settings')) {
            this.props.history.push('/dashboard');
        } else {
            this.props.history.goBack()
        }
    }

    render() {
        return (
            <>
                <div className='topbar-container'>
                    <div className='topbar-main-container'>
                        <div className='topbar-nav-container'>
                            <div className='topbar-backbutton-container'>
                                {(this.props.showBackButton) ?
                                    <IonIcon icon={arrowBackOutline} onClick={() => { this._backButtonPressed() }} /> : null
                                }
                            </div>
                            <div className='topbar-logo-container'>
                                {(this.props.showImage) ?
                                    <IonImg src='../../assets/sundairlogo.png' alt='Sundair Logo' /> : null
                                }

                            </div>
                            <div className='topbar-menubutton-container'>
                                {(this.props.showMenuButton) ?
                                    <IonIcon icon={ellipsisVertical}
                                        onClick={() => { this.setState({ showMenu: true }) }} /> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className='topbar-main-container topbar-sticky' id='sticky' ref='sticky'>
                        <div className='topbar-headline-container'>
                            <h1>
                                {this.props.headlineValue}
                            </h1>
                        </div>

                        {(this.props.showTextButton) ?
                            <>
                                <div className='topbar-textbutton-container'>
                                    <TextButton
                                        onClick={() => { this.props.onTextButtonClick() }}
                                        iconStyle={this.props.textButtonIconStyle}
                                        title={this.props.textButtonValue} />

                                </div>
                            </> : null
                        }

                        {(this.props.showSegment) ?
                            <>
                                <div className='topbar-segment-container'>
                                    <IonSegment
                                        mode='ios'
                                        value={this.props.activeSegmentIndex}
                                        onIonChange={(e) => { this.props.onSegmentChange(e.detail.value) }}>
                                        {this._renderSegmentButtons()}
                                    </IonSegment>
                                </div>
                            </> : null
                        }

                        {(this.props.showSearchBar) ?
                            <>
                                <div className='topbar-searchbar-container'>
                                    <IonSearchbar
                                        mode='ios'
                                        onIonChange={(e) => { this.props.onSearchBarChange(e.detail.value) }}
                                        placeholder={this.props.searchBarPlaceholder}>

                                    </IonSearchbar>
                                </div>
                            </> : null
                        }
                    </div>
                </div>
                <div className='topbar-push'></div>

                <IonPopover
                    isOpen={this.state.showMenu}
                    mode='ios'
                    cssClass='topbar-popover'
                    onDidDismiss={() => { this.setState({ showMenu: false }) }}>
                    <div>
                        <IonText className='topbar-popover-username'>
                            {this.props.username}
                        </IonText>
                        <IonList>
                            <IonItem className='topbar-popover-item' onClick={() => { this.setState({ showMenu: false }); this.props.history.push('/settings') }}>
                                <IonLabel>
                                    Einstellungen
                                </IonLabel>
                                <IonIcon icon={chevronForwardOutline} />
                            </IonItem>
                            <IonItem className='topbar-popover-item' onClick={() => { this.setState({ showMenu: false }); this.props.history.push('/login') }}>
                                <IonLabel>
                                    abmelden
                                </IonLabel>
                                <IonIcon icon={logOutOutline} />
                            </IonItem>
                        </IonList>
                    </div>
                </IonPopover>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.userinfos.username
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUsername: (username) => { dispatch(setUsername(username)) },
        setLoggedIn: (loggedIn) => { dispatch(setLoggedIn(loggedIn)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));