import { IonModal, IonText, IonLabel, IonList, IonItem } from '@ionic/react';
import React from 'react';

import CustomModal from './CustomModal';

//import './AddModal.css';

export default class FlightDetailModal extends CustomModal {
    state = {
        cat: 'product',
        article_name_ger: '',
        article_name_en: '',
        article_shortName: '',
        category_name_ger: '',
        category_name_en: '',
        category_shortName: ''
    }

    render() {
        return (
            <>
                <IonModal
                    className='custommodal-container'
                    isOpen={this.props.isOpen}
                    backdropDismiss={false}>

                    <div className='custommodal-header-container'>
                        <div className='custommodal-closebutton-container'>
                            {this._renderCloseButton()}
                        </div>
                        <div className='custommodal-headline-container'>
                            <IonText className='custommodal-headline'>{this.props.headlineValue}</IonText>
                        </div>
                    </div>

                    <IonList>
                        <IonItem>
                            <IonLabel>
                                Passagiere
                            </IonLabel>
                            1049
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                Gesamtumsatz
                            </IonLabel>
                            72319,56 €
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                Umsatz pro Passagier
                            </IonLabel>
                            68,94 €
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                Durchschnittlicher Rechnungsbetrag
                            </IonLabel>
                            82,16 €
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                Gesamtanzahl Produkte
                            </IonLabel>
                            10562
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                Gesamtanzahl Flüge
                            </IonLabel>
                            8
                        </IonItem>
                    </IonList>
                </IonModal>
            </>
        )
    }
}