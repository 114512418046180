import { IonItem, IonItemSliding, IonLabel, IonIcon, IonItemOptions, IonItemOption, IonLoading, IonToast } from '@ionic/react';
import { createOutline, trashOutline } from 'ionicons/icons';
import React from 'react';

import { renderPrice } from '../Helper';

import API from '../../API';

import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import '../../overall.css'

import './ProductDatabaseItem.css';

import { connect } from 'react-redux';

class ProductDatabaseItem extends React.Component {
    state = {
        showEditModal: false,
        showDeleteModal: false,
        showLoadingScreen: false,
        showToast: false
    }

    async _deleteMe(delProd) {
        this.setState({ showLoadingScreen: true });

        let message = '';
        //delete with api
        if (this.props.type === 'ARTICLE') {
            await new API().deleteProduct(delProd.id, this.props.basicAuth);
            message = `Das Produkt <b>${delProd.name}</b> wurde erfolgreich gelöscht.`;
        } else {
            await new API().deleteCategory(delProd.id, this.props.basicAuth);
            message = `Die Kategorie <b>${delProd.name}</b> wurde erfolgreich gelöscht.`;
        }

        //define toast message
        this.setState({ toastMessage: message }, () => {
            //show toast
            this.setState({ showToast: true });
        });

        //hide deleteModal
        this.setState({ showDeleteModal: false });

        //call callback to refresh the screen list

        this.setState({ showLoadingScreen: false });
        this.props.onChange();
    }

    async _editMe(newProd) {
        this.setState({ showLoadingScreen: true });

        let message = '';
        //edit with api
        if (this.props.type === 'ARTICLE') {
            await new API().editProduct(newProd.code, '', newProd.id, newProd.idCategory, newProd.name, newProd.price1, this.props.basicAuth);
            message = `Das Produkt <b>${newProd.name}</b> wurde erfolgreich bearbeitet.`;
        } else {
            await new API().editCategory(newProd.code, newProd.name, newProd.id, this.props.basicAuth);
            message = `Die Kategorie <b>${newProd.name}</b> wurde erfolgreich bearbeitet.`;
        }

        //define toast message
        this.setState({ toastMessage: message }, () => {
            //show toast
            this.setState({ showToast: true });
        });

        //hide editModal
        this.setState({ showEditModal: false });

        //call callback to refresh the screen list
        this.setState({ showLoadingScreen: false });
        this.props.onChange();
    }

    _renderName() {
        if (this.props.hightlight) {
            //get part to highlight
            let partIndex = this.props.obj.name.toLowerCase().indexOf(this.props.hightlight);

            //start
            let start = this.props.obj.name.slice(0, partIndex);

            //middle
            let middle = this.props.obj.name.substring(partIndex, partIndex + this.props.hightlight.length);

            //end
            let end = this.props.obj.name.slice(partIndex + this.props.hightlight.length);

            return (<h1 className='productdatabase-headline'>{start}{this._showHighlight(middle)}{end}</h1>)
        } else {
            return (<h1 className='productdatabase-headline'>{this.props.obj.name}</h1>)
        }
    }

    _showHighlight(text) {
        if (text) {
            return <span className='text-highlight'>{text}</span>;
        } else {
            return null;
        }
    }

    render() {
        return (
            <>
                <IonLoading
                    isOpen={this.state.showLoadingScreen}
                    onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                    cssClass='spinner'
                    spinner={null} />

                <IonToast
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({ showToast: false })}
                    message={this.state.toastMessage}
                    position="top"
                    buttons={[
                        {
                            text: 'OK',
                            role: 'cancel',
                        }
                    ]} />

                <IonItemSliding>
                    <IonItem>
                        <IonLabel className='productdatabase-label ion-text-wrap'>
                            {this._renderName()}
                            {(this.props.type === 'ARTICLE') ? <p className='productdatabase-text'>Preis: {renderPrice(this.props.obj.price1, 'EUR')}</p> : null}
                        </IonLabel>
                        <IonIcon onClick={() => this.setState({ showEditModal: true })} color='tertiary' className='productdatabase-item-icon' icon={createOutline} />
                        <IonIcon onClick={() => { this.setState({ showDeleteModal: true }) }} color='danger' className='productdatabase-item-icon' icon={trashOutline} />
                    </IonItem>

                    <IonItemOptions side='end'>
                        <IonItemOption expandable color='tertiary' onClick={() => { this.setState({ showEditModal: true }) }}>
                            <IonIcon slot='top' icon={createOutline} />
                            Bearbeiten
                        </IonItemOption>
                        <IonItemOption expandable color='danger' onClick={() => { this.setState({ showDeleteModal: true }) }}>
                            <IonIcon slot='top' icon={trashOutline} />
                            Löschen
                        </IonItemOption>
                    </IonItemOptions>
                </IonItemSliding>

                <DeleteModal
                    isOpen={this.state.showDeleteModal}
                    showCloseButton={true}
                    obj={this.props.obj}
                    prods={this.props.prods}
                    headlineValue={this.props.obj.name}
                    onCloseButtonPressed={() => { this.setState({ showDeleteModal: false }); this.props.onChange() }}
                    onDeleteButtonPressed={(delProd) => { this._deleteMe(delProd) }} />

                <EditModal
                    isOpen={this.state.showEditModal}
                    showCloseButton={true}
                    headlineValue={this.props.obj.name}
                    obj={this.props.obj}
                    prods={this.props.prods}
                    categories={this.props.categories}
                    onCloseButtonPressed={() => { this.setState({ showEditModal: false }); this.props.onChange() }}
                    onChangeButtonPressed={(newProd) => { this._editMe(newProd) }} />
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        basicAuth: state.userinfos.basicAuth
    }
}

export default connect(mapStateToProps, null)(ProductDatabaseItem);