import { IonModal, IonText, IonButton, IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

import CustomModal from './CustomModal';

import './AddModal.css';
import { renderPrice } from '../Helper';

export default class AddModal extends CustomModal {
    state = {
        cat: 'product',
        article_name_ger: '',
        article_name_en: '',
        article_shortName: '',
        article_price: '0',
        article_category: '',
        category_name_ger: '',
        category_name_en: '',
        category_shortName: ''
    }

    _renderCategories() {
        let ret = [];

        const cats = this.props.categoriesShortName;
        const catsNames = this.props.categoriesLongName;

        for (let i = 0; i < cats.length; i++) {
            ret.push(
                <IonSelectOption value={cats[i]} key={i}>
                    {`${catsNames[i]} (${cats[i]})`}
                </IonSelectOption>
            )
        }

        return ret;
    }

    async _getEnglishTranslation(type) {
        let words = this.state.article_name_ger;
        if (type === 'CATEGORY') {
            words = this.state.category_name_ger;
        }

        //check if there is already a translation 
        //then don't override it
        let inputEmpty = false;
        if (type === 'CATEGORY') {
            inputEmpty = this.state.category_name_en.length === 0;
        } else {
            inputEmpty = this.state.article_name_en.length === 0;
        }

        if (words.length > 0 && inputEmpty) {
            await fetch(`https://api.mymemory.translated.net/get?q=${words}&langpair=de|en`).then((res) => {
                return res.json();
            }).then((data) => {
                if (data.matches.length > 0) {
                    let translation = data.matches[0].translation
                    //auto generate the translation
                    if (type === 'CATEGORY') {
                        this.setState({ category_name_en: translation });
                    } else {
                        this.setState({ article_name_en: translation });
                    }

                    //auto generate the short name based on the translation
                    let subWords = translation.split(' ');

                    let shortName = '';

                    switch (subWords.length) {
                        case 1:
                            shortName = translation.substring(0, 4);
                            break;
                        case 2:
                            shortName = subWords[0].substring(0, 2) + subWords[1].substring(0, 2);
                            break;
                        case 3:
                            shortName = subWords[0].substring(0, 2) + subWords[1].substring(0, 1) + subWords[2].substring(0, 1);
                            break;
                        case 4:
                            shortName = subWords[0].substring(0, 1) + subWords[1].substring(0, 1) + subWords[2].substring(0, 1) + subWords[3].substring(0, 1);
                            break;
                        default:
                            shortName = '';
                    }

                    if (type === 'CATEGORY') {
                        this.setState({ category_shortName: shortName });
                    } else {
                        this.setState({ article_shortName: shortName });
                    }
                }
            })
        }
    }

    _renderHeadline() {
        if (this.props.type === 'product') {
            return 'Produkt hinzufügen'
        } else {
            return 'Kategorie hinzufügen'
        }
    }

    _buttonPressed(type) {
        if (type === 'ARTICLE') {
            this.props.onButtonPressed({
                type: 'ADD_ARTICLE',
                name_de: this.state.article_name_ger,
                name_en: this.state.article_name_en,
                price: parseFloat(this.state.article_price.replace(',', '.')),
                category: this.state.article_category,
                code: this.state.article_shortName
            });

            //clear iputs
            this.setState({
                article_category: '',
                article_name_en: '',
                article_name_ger: '',
                article_price: '0',
                article_shortName: ''
            });
        } else {
            this.props.onButtonPressed({
                type: 'ADD_CATEGORY',
                name_de: this.state.category_name_ger,
                name_en: this.state.category_name_en,
                code: this.state.category_shortName
            });

            //clear iputs
            this.setState({
                category_name_ger: '',
                category_name_en: '',
                category_shortName: ''
            });
        }
    }

    _doesProductShortNameExist(code) {
        let found = false;
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i].code === code) {
                found = true;
                break;
            }
        }

        return found;
    }

    _doesProductNameExist(name) {
        let found = false;
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i].name === name) {
                found = true;
                break;
            }
        }

        return found;
    }

    _renderInput() {
        if (this.props.type === 'product') {
            return (
                <>
                    <IonList>
                        <IonItem>
                            <IonLabel position='floating'>Name (deutsch)</IonLabel>
                            <IonInput
                                onIonChange={(e) => { this.setState({ article_name_ger: e.detail.value }) }}
                                onBlur={() => { this._getEnglishTranslation() }}
                                value={this.state.article_name_ger}>
                            </IonInput>
                            {(this._doesProductNameExist(this.state.article_name_ger)) ?
                                <p className='addmodal-error'>dieser Name existiert bereits</p>
                                : null}
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Name (englisch)</IonLabel>
                            <IonInput
                                value={this.state.article_name_en}
                                onIonChange={(e) => { this.setState({ article_name_en: e.detail.value }) }}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Kategorie</IonLabel>
                            <IonSelect
                                onIonChange={(e) => this.setState({ article_category: e.detail.value })}
                                value={this.state.article_category}>
                                {this._renderCategories()}
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Kürzel</IonLabel>
                            <IonInput
                                onIonChange={(e) => { this.setState({ article_shortName: e.detail.value.toUpperCase() }) }}
                                value={this.state.article_shortName}
                                maxlength={4}></IonInput>
                            {(this._doesProductShortNameExist(this.state.article_shortName)) ?
                                <p className='addmodal-error'>dieses Kürzel existiert bereits</p>
                                : null}
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Preis in Euro</IonLabel>
                            <IonInput
                                onIonChange={(e) => { this.setState({ article_price: e.detail.value }) }}
                                value={this.state.article_price}
                                inputmode='decimal'>
                            </IonInput>
                            <IonLabel
                                position='stacked'
                                slot='end'>
                                Vorschau: {renderPrice(parseFloat(this.state.article_price.replace(',', '.')), 'EUR')}
                            </IonLabel>
                        </IonItem>
                    </IonList>

                    <div className='addmodal-button-container'>
                        <IonButton
                            mode='ios'
                            disabled={this.state.article_name_en.length === 0 ||
                                this.state.article_name_ger.length === 0 ||
                                this.state.article_shortName.length === 0 ||
                                this.state.article_category.length === 0 ||
                                this.state.article_price <= 0 ||
                                this._doesProductShortNameExist(this.state.article_shortName) ||
                                this._doesProductNameExist(this.state.article_name_ger)}
                            onClick={() => { this._buttonPressed('ARTICLE') }}>
                            Produkt hinzufügen
                    </IonButton>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <IonList>
                        <IonItem>
                            <IonLabel position='floating'>Name (deutsch)</IonLabel>
                            <IonInput
                                onIonChange={(e) => { this.setState({ category_name_ger: e.detail.value }) }}
                                onBlur={() => { this._getEnglishTranslation('CATEGORY') }}>
                            </IonInput>
                            {(this.props.categoriesLongName.includes(this.state.category_name_ger)) ?
                                <p className='addmodal-error'>diese Kategorie existiert bereits</p>
                                : null}
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Name (englisch)</IonLabel>
                            <IonInput
                                value={this.state.category_name_en}
                                onIonChange={(e) => { this.setState({ category_name_en: e.detail.value }) }}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position='floating'>Kürzel</IonLabel>
                            <IonInput
                                onIonChange={(e) => { this.setState({ category_shortName: e.detail.value.toUpperCase() }) }}
                                value={this.state.category_shortName}
                                maxlength={4}></IonInput>
                            {(this.props.categoriesShortName.includes(this.state.category_shortName)) ?
                                <p className='addmodal-error'>dieses Kürzel existiert bereits</p>
                                : null}
                        </IonItem>
                    </IonList>
                    <IonButton
                        mode='ios'
                        disabled={this.state.category_name_ger.length === 0 ||
                            this.state.category_name_en.length === 0 ||
                            this.state.category_shortName.length === 0 ||
                            this.props.categoriesShortName.includes(this.state.category_shortName) ||
                            this.props.categoriesLongName.includes(this.state.category_name_ger)}
                        onClick={() => { this._buttonPressed('CATEGORY') }}>
                        Kategorie hinzufügen
                    </IonButton>
                </>
            )
        }
    }

    render() {
        return (
            <>
                <IonModal
                    className='custommodal-container'
                    isOpen={this.props.isOpen}
                    backdropDismiss={false}>

                    <div className='custommodal-header-container'>
                        <div className='custommodal-closebutton-container'>
                            {this._renderCloseButton()}
                        </div>
                        <div className='custommodal-headline-container'>
                            <IonText className='custommodal-headline'>{this._renderHeadline()}</IonText>
                        </div>
                    </div>

                    {this._renderInput()}
                </IonModal>
            </>
        )
    }
}