import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import './Dashboard.css';

import Topbar from '../js/components/Topbar';
import MenuCard from '../js/components/MenuCard';
import { connect } from 'react-redux';

import '../overall.css'

class Dashboard extends React.Component {
    state = {
        username: '',
        password: ''
    }

    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }

        this._redirectIfDesktopView();
    }

    _redirectIfDesktopView() {
        //check if user is in mobile view or in desktop view
        //in desktop view redirect to revenue
        //in mobile view do nothing more here
        if (window.innerWidth > 900) {
            //we're on desktop view
            this.props.history.push('/revenue');
        }
    }

    render() {
        return (
            <IonPage className='dashboard-page'>
                <IonContent fullscreen>
                    <div className='flex-main-container'>
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={false}
                                showMenuButton={true}
                                showImage={true} />
                            <div className='dashboard-grid'>
                                <MenuCard
                                    show={this.props.roleUser}
                                    title='Umsatz'
                                    highlight={true}
                                    imageName='money.png'
                                    onClick={() => { this.props.history.push('/revenue') }} />

                                <MenuCard
                                    show={this.props.roleUser}
                                    title='Beste Produkte'
                                    highlight={true}
                                    imageName='coffee.png'
                                    onClick={() => { this.props.history.push('/products') }} />

                                <MenuCard
                                    show={this.props.roleUser}
                                    title='Beste Flüge'
                                    highlight={true}
                                    imageName='flight.png'
                                    onClick={() => { this.props.history.push('/flights') }} />

                                <MenuCard
                                    show={this.props.roleUser}
                                    title='Beste Crewmitglieder'
                                    highlight={true}
                                    imageName='attendants.png'
                                    onClick={() => { this.props.history.push('/crewmembers') }} />

                                <MenuCard
                                    show={this.props.roleUser && this.props.roleAdmin}
                                    title='Produktdatenbank'
                                    highlight={false}
                                    imageName='database.png'
                                    onClick={() => { this.props.history.push('/database') }} />

                                <MenuCard
                                    show={this.props.roleUser && this.props.roleAdmin}
                                    title='Crewumsätze'
                                    highlight={false}
                                    imageName='comission.png'
                                    onClick={() => { this.props.history.push('/sales') }} />
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        roleUser: state.userinfos.roleUser,
        roleAdmin: state.userinfos.roleAdmin,
    }
}

export default connect(mapStateToProps, null)(Dashboard);