import { IonContent, IonPage, IonList, IonItem, IonLabel, IonLoading } from "@ionic/react";
import React from "react";

import "./Sales.css";

import Topbar from "../js/components/Topbar";
import NewCalendarModal from "../js/components/NewCalendarModal";
import ErrorMessage from "../js/components/ErrorMessage";

import { renderPrice } from "../js/Helper";

import SideMenu from "../js/components/SideMenu";
import SettingsMenu from "../js/components/SettingsMenu";
import "../overall.css";

import API from "../API";

import { connect } from "react-redux";

class Sales extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        currentCalendarStartDate: new Date(),
        currentCalendarEndDate: new Date(),

        showCalendar: false,

        searchText: "",

        members: [],

        lastUpdateTime: "",
    };

    _showHighlight(text) {
        if (text) {
            return <span className="text-highlight">{text}</span>;
        } else {
            return null;
        }
    }

    _renderMemberList() {
        let ret = [];

        for (let i = 0; i < this.state.members.length; i++) {
            let act = this.state.members[i];

            console.log(act);

            if (act.resourceName.toLowerCase().includes(this.state.searchText.toLowerCase())) {
                //get part to highlight
                let partIndex = act.resourceName.toLowerCase().indexOf(this.state.searchText.toLowerCase());

                //start
                let start = act.resourceName.slice(0, partIndex);

                //middle
                let middle = act.resourceName.substring(partIndex, partIndex + this.state.searchText.length);

                //end
                let end = act.resourceName.slice(partIndex + this.state.searchText.length);

                ret.push(
                    <IonItem key={i}>
                        <div className="sales-number">
                            <p>{i + 1}.</p>
                        </div>
                        <IonLabel className="ion-text-wrap sales-label">
                            <h1>
                                {start}
                                {this._showHighlight(middle)}
                                {end}
                            </h1>
                            <p>Umsatz: {renderPrice(act.brutto, "EUR")}</p>
                            <p>Steuern: {renderPrice(act.vat, "EUR")}</p>
                            <p className="sales-bold">Netto: {renderPrice(act.netto, "EUR")}</p>
                            <p className="sales-bold sales-black">Provision: {renderPrice(act.comission, "EUR")}</p>
                        </IonLabel>
                    </IonItem>
                );
            }
        }

        //check if is data to show
        if (ret.length === 0) {
            ret.push(<ErrorMessage key={0} />);
        }

        return ret;
    }

    async componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.push({ pathname: "/login", state: { detail: window.location.href.replace("http://localhost:8100", "") } });
        }

        //begin of the current month
        let begin = new Date();
        begin.setDate(1);
        begin.setMonth(begin.getMonth());

        //today
        let end = new Date();

        this.setState({ startDate: begin, endDate: end });

        this._loadNewData(begin, end);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== "/sales" &&
            this.props.location.pathname.startsWith("/sales")
        ) {
            this._loadNewData(this.state.startDate, this.state.endDate);
        }
    }

    _renderDate(d) {
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let seconds = d.getSeconds();

        return `${day}.${month}.${year} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} Uhr`;
    }

    async _loadNewData(startDate, endDate) {
        this.setState({ showLoadingScreen: true });

        //get the user wanted data
        let crew = await new API().getCommission(startDate, endDate, this.props.basicAuth);

        for (let i = 0; i < crew.length; i++) {
            crew[i].resourceName = crew[i].resourceName.split(",").reverse().join(" ");
        }

        crew.sort((a, b) => {
            return a.resourceName > b.resourceName ? -1 : 1;
        });

        this.setState({ members: crew });

        //load last update time
        let time = await new API().getLastUpdateTime(this.props.basicAuth);

        if (time) {
            this.setState({ lastUpdateTime: this._renderDate(time) });
        } else {
            this.setState({ lastUpdateTime: "" });
        }

        this.setState({ showLoadingScreen: false });
    }

    _getURLParamterFromDate(date) {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    _closeButtonPressed() {
        //nothing to do here
        //i hope so
    }

    _calendarChange(value) {
        this.setState({ currentCalendarStartDate: value.start });
        this.setState({ currentCalendarEndDate: value.end });
    }

    _setNewDates() {
        this.setState({ startDate: this.state.currentCalendarStartDate, endDate: this.state.currentCalendarEndDate }, () => {
            this.setState({ showCalendar: false });

            this._loadNewData(this.state.startDate, this.state.endDate);
        });
    }

    _openCalendar() {
        this.setState({ currentCalendarEndDate: this.state.endDate, currentCalendarStartDate: this.state.startDate }, () => {
            this.setState({ showCalendar: true });
        });
    }

    render() {
        return (
            <>
                <IonPage>
                    <IonLoading
                        isOpen={this.state.showLoadingScreen}
                        onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                        cssClass="spinner"
                        spinner={null}
                    />
                    <IonContent forceOverscroll={true} fullscreen class="main-container">
                        <div className="flex-main-container">
                            <SideMenu />
                            <div className="flex-content-container">
                                <Topbar
                                    showBackButton={true}
                                    showMenuButton={true}
                                    showImage={true}
                                    headlineValue="Crewumsätze"
                                    showTextButton={true}
                                    textButtonValue={`${
                                        this.state.startDate && this.state.endDate
                                            ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                              " - " +
                                              this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                            : ""
                                    }`}
                                    textButtonIconStyle="calendar"
                                    onTextButtonClick={() => {
                                        this._openCalendar();
                                    }}
                                    showSearchBar={true}
                                    searchBarPlaceholder="Name oder 3-Letter Code"
                                    onSearchBarChange={(v) => {
                                        this.setState({ searchText: v });
                                    }}
                                />

                                <IonList className="sales-list-container">
                                    <div className="sales-update-banner">
                                        <p>Stand der Daten: {this.state.lastUpdateTime}</p>
                                    </div>
                                    {this._renderMemberList()}
                                </IonList>
                            </div>
                            <SettingsMenu
                                textButtonOnClick={() => {
                                    this._openCalendar();
                                }}
                                dateString={`${
                                    this.state.startDate && this.state.endDate
                                        ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                          " - " +
                                          this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                        : ""
                                }`}
                            />
                        </div>
                    </IonContent>
                </IonPage>

                <NewCalendarModal
                    isOpen={this.state.showCalendar}
                    showCloseButton={true}
                    onCloseButtonPressed={() => {
                        this.setState({ showCalendar: false });
                    }}
                    startDate={this.state.currentCalendarStartDate}
                    endDate={this.state.currentCalendarEndDate}
                    onChange={(v) => {
                        this._calendarChange(v);
                    }}
                    onSuccess={() => {
                        this._setNewDates();
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        basicAuth: state.userinfos.basicAuth,
    };
};

export default connect(mapStateToProps, null)(Sales);
