import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons'
import React from 'react';

import './ErrorMessage.css';

export default class ErrorMessage extends React.Component {
    render() {
        return (
            <div className='error-nodata'>
                <IonIcon className='error-nodata-icon' icon={alertCircleOutline} />
                <h2>Keine Daten</h2>
                <p>Für die angegebenen Parameter konnten keine Daten gefunden werden.</p>
            </div>
        )
    }
}