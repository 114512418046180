import { IonContent, IonPage, IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

import '../../Settings.css';

import Topbar from '../../../js/components/Topbar';

import SideMenu from '../../../js/components/SideMenu';
import '../../../overall.css'

import { connect } from 'react-redux';
import { setSettingsStandardRevenueSegment } from '../../../js/redux/actions/settingsactions';

class SettingsValuesRevenue extends React.Component {
    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }
    }

    _change(type, value) {
        if (type === 'SEGMENT') {
            this.props.setSegmentIndex(value);
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                headlineValue='Umsatz' />
                            <IonList className='settings-list'>
                                <IonItem className='settings-item'>
                                    <IonLabel>
                                        Segment
                                    </IonLabel>
                                    <IonSelect onIonChange={(e) => { this._change('SEGMENT', e.detail.value) }} value={this.props.settings.standardRevenueSegment}>
                                        <IonSelectOption value={'0'}>
                                            Kreditkarte
                                        </IonSelectOption>
                                        <IonSelectOption value={'1'}>
                                            Bar
                                        </IonSelectOption>
                                        <IonSelectOption value={'2'}>
                                            Gesamt
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSegmentIndex: (idx) => { dispatch(setSettingsStandardRevenueSegment(idx)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsValuesRevenue);