import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonNav } from '@ionic/react';
import { IonReactRouter, IonReactHashRouter } from '@ionic/react-router';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProductDatabase from './pages/ProductDatabase';
import Settings from './pages/Settings';
import SettingsDates from './pages/settings/SettingsDates';
import SettingsValues from './pages/settings/SettingsValues';
import SettingsInfo from './pages/settings/SettingsInfo';

import SettingsValuesRevenue from './pages/settings/settingsvalues/SettingsValuesRevenue';
import SettingsValuesProducts from './pages/settings/settingsvalues/SettingsValuesProducts';
import SettingsValuesFlight from './pages/settings/settingsvalues/SettingsValuesFlight';
import SettingsValuesCrewmembers from './pages/settings/settingsvalues/SettingsValuesCrewmembers';
import SettingsValuesSales from './pages/settings/settingsvalues/SettingsValuesSales';

import Products from './pages/Products';
import Revenue from './pages/Revenue';
import Flights from './pages/Flight';
import Crewmembers from './pages/Crewmember';
import Sales from './pages/Sales';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonNav swipeGesture={false}>
      <IonReactHashRouter>
        <IonRouterOutlet
          animated={false}>
          <Route path="/login" component={Login} exact={true} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/dashboard" component={Dashboard} exact={true} />
          <Route path="/database" component={ProductDatabase} exact={true} />
          <Route path="/products" component={Products} exact={true} />
          <Route path="/revenue" component={Revenue} exact={true} />
          <Route path="/flights" component={Flights} exact={true} />
          <Route path="/crewmembers" component={Crewmembers} exact={true} />
          <Route path="/sales" component={Sales} exact={true} />

          <Route path="/settings" component={Settings} exact={true} />
          <Route path="/settings/dates" component={SettingsDates} exact={true} />
          <Route path="/settings/values" component={SettingsValues} exact={true} />
          <Route path="/settings/info" component={SettingsInfo} exact={true} />

          <Route path="/settings/values/revenue" component={SettingsValuesRevenue} exact={true} />
          <Route path="/settings/values/flights" component={SettingsValuesFlight} exact={true} />
          <Route path="/settings/values/products" component={SettingsValuesProducts} exact={true} />
          <Route path="/settings/values/sales" component={SettingsValuesSales} exact={true} />
          <Route path="/settings/values/crewmembers" component={SettingsValuesCrewmembers} exact={true} />
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonNav>
  </IonApp >
);

export default App;
