import { IonContent, IonPage, IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

import '../../Settings.css';

import Topbar from '../../../js/components/Topbar';

import SideMenu from '../../../js/components/SideMenu';
import '../../../overall.css'

import { connect } from 'react-redux';
import {
    setSettingsStandardCrewmembersTop,
    setSettingsStandardCrewmembersSegment
} from '../../../js/redux/actions/settingsactions';

class SettingsValuesCrewmembers extends React.Component {
    componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.replace('/login')
        }
    }

    _getTopValue(idx) {
        if (idx === 0) {
            return 3;
        } else if (idx === 1) {
            return 5;
        } else if (idx === 2) {
            return 10;
        } else {
            return 100000;
        }
    }

    _change(type, value) {
        if (type === 'SEGMENT') {
            this.props.setSegmentIndex(value);
        } else if (type === 'TOP') {
            this.props.setTop({ index: parseInt(value), value: parseInt(this._getTopValue(parseInt(value))) })
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent fullscreen class='main-container'>
                    <div className='flex-main-container'>
                        <SideMenu />
                        <div className='flex-content-container'>
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                headlineValue='Beste Crewmitglieder' />
                            <IonList className='settings-list'>
                                <IonItem className='settings-item'>
                                    <IonLabel>
                                        Segment
                            </IonLabel>
                                    <IonSelect onIonChange={(e) => { this._change('SEGMENT', e.detail.value) }} value={this.props.settings.standardProductsSegment}>
                                        <IonSelectOption value={'0'}>
                                            Umsatz
                                        </IonSelectOption>
                                        <IonSelectOption value={'1'}>
                                            Anzahl
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem className='settings-item'>
                                    <IonLabel>
                                        Top-Auswahl
                            </IonLabel>
                                    <IonSelect onIonChange={(e) => { this._change('TOP', e.detail.value) }} value={this.props.settings.standardProductsTopIndex}>
                                        <IonSelectOption value={0}>
                                            Top 3
                                        </IonSelectOption>
                                        <IonSelectOption value={1}>
                                            Top 5
                                        </IonSelectOption>
                                        <IonSelectOption value={2}>
                                            Top 10
                                        </IonSelectOption>
                                        <IonSelectOption value={3}>
                                            alle
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonList>
                        </div>
                    </div>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSegmentIndex: (idx) => { dispatch(setSettingsStandardCrewmembersSegment(idx)) },
        setTop: (o) => { dispatch(setSettingsStandardCrewmembersTop(o)) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SettingsValuesCrewmembers);