import { IonContent, IonPage, IonFooter, IonLoading, IonText, IonItem, IonLabel, IonInput, IonButton, IonIcon } from "@ionic/react";
import React from "react";

import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import "./Products.css";

import Topbar from "../js/components/Topbar";
import Buttonbar from "../js/components/Buttonbar";
import ProductItem from "../js/components/ProductItem";
import NewCalendarModal from "../js/components/NewCalendarModal";
import ErrorMessage from "../js/components/ErrorMessage";

import SideMenu from "../js/components/SideMenu";
import SettingsMenu from "../js/components/SettingsMenu";
import "../overall.css";

import API from "../API";

import { connect } from "react-redux";
import { documentOutline, star } from "ionicons/icons";

const colors = ["#ee6352", "#59cd90", "#3fa7d6", "#fac05e", "#1b4079", "#4e598c", "#35605a", "#acedff", "#140d4f", "#92374d"];

const grey = "#475657";

class Products extends React.Component {
    state = {
        products: [],
        presentationProducts: [],
        pieProducts: [],
        showLoadingScreen: true,
        optionButtonbar: this.props.settings.standardProductsTopIndex,
        valueButtonbar: this.props.settings.standardProductsTopValue,
        activeSegmentIndex: this.props.settings.standardProductsSegment,

        startDate: new Date(),
        endDate: new Date(),
        currentCalendarStartDate: new Date(),
        currentCalendarEndDate: new Date(),

        airportFromIataCode: null,
        airportToIataCode: null,
        showAirportInputs: false,
        airportString: "alle",
        flightNo: null,
    };


    setFlightNo(flightNo) {
        this.setState({ flightNo: flightNo });
    }

    setAirportString(airportString) {
        this.setState({ airportString: airportString });
    }

    setAirportFrom(iataCode) {
        let newIataCode = null;

        if (iataCode) {
            newIataCode = iataCode.toUpperCase();
        }

        this.setState({ airportFromIataCode: newIataCode });
    }

    setAirportTo(iataCode) {
        let newIataCode = null;

        if (iataCode) {
            newIataCode = iataCode.toUpperCase();
        }

        this.setState({ airportToIataCode: newIataCode });
    }

    async componentDidMount() {
        //bring user to login page, if not logged in
        if (!this.props.loggedIn) {
            this.props.history.push({ pathname: "/login", state: { detail: window.location.href.replace("http://localhost:8100", "") } });
        }

        //begin of the current month
        let begin = new Date();
        begin.setDate(1);
        begin.setMonth(begin.getMonth());

        //today
        let end = new Date();

        this.setState({ startDate: begin, endDate: end });

        await this._loadNewData(begin, end);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== "/products" &&
            this.props.location.pathname.startsWith("/products")
        ) {
            this._loadNewData(this.state.startDate, this.state.endDate);
        }
    }

    _getURLParamterFromDate(date) {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    _createShareLink() {
        let ret = "";

        ret += ret += window.location.href.replace(window.location.pathname, "");
        ret += "/products?";
        ret += "start=" + this._getURLParamterFromDate(this.state.startDate) + "&";
        ret += "end=" + this._getURLParamterFromDate(this.state.endDate) + "&";
        ret += "seg=" + this.state.activeSegmentIndex + "&";
        ret += "top=" + this.state.optionButtonbar;

        return ret;
    }

    async _loadNewData(startDate, endDate) {
        this.setState({ showLoadingScreen: true });

        //get the user wanted data
        let prods = await new API().getBestProducts(startDate, endDate, this.state.airportFromIataCode, this.state.airportToIataCode, this.state.flightNo, this.props.basicAuth);

        //sort by users segment selection
        //0 = totalRevenueEUR
        //1 = totalSold

        if (this.state.activeSegmentIndex === 0) {
            prods.sort((a, b) => {
                return a.totalRevenueEUR < b.totalRevenueEUR ? 1 : -1;
            });
        } else {
            prods.sort((a, b) => {
                return a.totalSold < b.totalSold ? 1 : -1;
            });
        }

        //cut out the relevant part
        //filter options and some data for the pie chart
        let [presentationArray, dataArray] = this._getPresentationArrays(prods);

        this.setState({ products: prods, presentationProducts: presentationArray, pieProducts: dataArray });

        this.setState({ showLoadingScreen: false });
    }

    _getPresentationArrays(prods) {
        //get the first relevant options
        let relevantProducts = prods.slice(0, this.state.valueButtonbar);

        //get the rest of the products
        let irrelevantProducts = prods.slice(this.state.valueButtonbar, prods.length);

        //calculate the "Rest" product and push it to the relevant array
        let restTotalSold = 0;
        let restTotalRevenueEUR = 0;

        irrelevantProducts.forEach((prod) => {
            restTotalSold += prod.totalSold;
            restTotalRevenueEUR += prod.totalRevenueEUR;
        });

        if (restTotalSold !== 0 || restTotalRevenueEUR !== 0) {
            relevantProducts.push({
                productName: "Rest",
                totalSold: restTotalSold,
                totalRevenueEUR: restTotalRevenueEUR,
            });
        }

        //calculate the data array for the pie chart
        let data = [];
        relevantProducts.forEach((prod, index, arr) => {
            //the last item is the "Rest" item,
            //it gets a grey color
            let color = colors[index];
            if (index === arr.length - 1 && prod.productName === "Rest") {
                color = grey;
            }

            data.push({
                data: this.state.activeSegmentIndex === "0" ? prod.totalRevenueEUR : prod.totalSold,
                color: color,
                productName: prod.productName,
            });
        });

        //reverse the data array, so the pie looks better
        data = data.reverse();

        return [relevantProducts, data];
    }

    _renderProductList() {
        let ret = [];

        for (let i = 0; i < this.state.presentationProducts.length; i++) {
            let act = this.state.presentationProducts[i];

            //the last item is the "Rest" item,
            //it gets a grey color
            let color = colors[i];
            let rest = false;
            if (i === this.state.presentationProducts.length - 1 && this.state.presentationProducts[i].productName === "Rest") {
                color = grey;
                rest = true;
            }

            ret.push(
                <ProductItem
                    key={i}
                    highlightRevenue={this.state.activeSegmentIndex === "0"}
                    color={color}
                    rest={rest}
                    headline={act.productName}
                    price={act.totalRevenueEUR / act.totalSold}
                    amount={act.totalSold}
                />
            );
        }

        //check if is data to show
        if (ret.length === 0) {
            ret.push(<ErrorMessage key={0} />);
        }

        return ret;
    }

    _calendarChange(value) {
        this.setState({ currentCalendarStartDate: value.start });
        this.setState({ currentCalendarEndDate: value.end });
    }

    _setNewDates() {
        this.setState({ startDate: this.state.currentCalendarStartDate, endDate: this.state.currentCalendarEndDate }, () => {
            this.setState({ showCalendar: false });

            this._loadNewData(this.state.startDate, this.state.endDate);
        });
    }

    _openCalendar() {
        this.setState({ currentCalendarEndDate: this.state.endDate, currentCalendarStartDate: this.state.startDate }, () => {
            this.setState({ showCalendar: true });
        });
    }

    _changeSegmentFilter(index) {
        //0 = revenue
        //1 = number
        this.setState({ activeSegmentIndex: index });

        let copy = this.state.products;
        //sort products by revenue/number
        if (index === "0") {
            copy.sort((a, b) => {
                return a.totalRevenueEUR < b.totalRevenueEUR;
            });
        } else {
            copy.sort((a, b) => {
                return a.totalSold < b.totalSold;
            });
        }

        this.setState({ products: copy }, () => {
            //update the displayed products
            //filter options and some data for the pie chart
            let [presentationArray, dataArray] = this._getPresentationArrays(this.state.products);

            this.setState({ presentationProducts: presentationArray, pieProducts: dataArray });
        });
    }

    _changeTopFilter(index, value) {
        //update state
        this.setState({ optionButtonbar: index, valueButtonbar: value }, () => {
            //update the displayed products
            //filter options and some data for the pie chart
            let [presentationArray, dataArray] = this._getPresentationArrays(this.state.products);

            this.setState({ presentationProducts: presentationArray, pieProducts: dataArray });
        });
    }

    _renderLabel(entry) {
        return entry.productName;
    }

    _toggleAirportInputs() {
        this.setState({ showAirportInputs: !this.state.showAirportInputs });
    }

    _searchFlightsClicked() {

        let airportString = "alle",
            iataCodesSet = false;


        this._loadNewData(this.state.startDate, this.state.endDate);
        this.setState({ showAirportInputs: false });

        if (this.state.airportToIataCode && this.state.airportFromIataCode) {
            iataCodesSet = true;
            airportString = this.state.airportFromIataCode + "-" + this.state.airportToIataCode;
        }
        if(this.state.flightNo) {
            if(iataCodesSet === true) {
                airportString += ", " + this.state.flightNo;
            }
            if(iataCodesSet === false) {
                airportString = this.state.flightNo;
            }
        }

        this.setState({ airportString: airportString });
    }


    async onClick_ReportButton() {

        this.setState({ showLoadingScreen: true });

        await new API().getProductsReport(this.state.startDate, this.state.endDate, this.state.airportFromIataCode, this.state.airportToIataCode, this.state.flightNo, this.props.basicAuth);

        this.setState({ showLoadingScreen: false });
    }

    render() {
        return (
            <IonPage>
                <IonLoading
                    isOpen={this.state.showLoadingScreen}
                    onDidDismiss={() => this.setState({ showLoadingScreen: false })}
                    cssClass="spinner"
                    spinner={null}
                />
                <IonContent fullscreen class="main-container">
                    <div className="flex-main-container">
                        <SideMenu />
                        <div className="flex-content-container">
                            <Topbar
                                showBackButton={true}
                                showMenuButton={true}
                                showImage={true}
                                headlineValue="Beste Produkte"
                                showTextButton={true}
                                textButtonValue={`${
                                    this.state.startDate && this.state.endDate
                                        ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                          " - " +
                                          this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                        : ""
                                }`}
                                textButtonIconStyle="calendar"
                                onTextButtonClick={() => {
                                    this._openCalendar();
                                }}
                                showSegment={true}
                                segmentElements={["Umsatz", "Anzahl"]}
                                activeSegmentIndex={this.state.activeSegmentIndex}
                                onSegmentChange={(idx) => {
                                    this._changeSegmentFilter(idx);
                                }}
                            />

                            <div className="buttonbar-container">
                                <Buttonbar
                                    options={["Top 3", "Top 5", "Top 10", "alle"]}
                                    values={[3, 5, 10, 10000]}
                                    activeIndex={this.state.optionButtonbar}
                                    onChange={(idx, value) => {
                                        this._changeTopFilter(idx, value);
                                    }}
                                />
                            </div>

                            <IonButton onClick={() => this.onClick_ReportButton()} size="small" mode="ios" style={{ marginTop: 0, padding: 0, marginLeft: "1rem", width: 310 }}>
                                <IonIcon slot="start" icon={documentOutline} />
                                Report
                            </IonButton>

                            <IonText onClick={() => this._toggleAirportInputs()}>
                                <p className="ion-margin-start" style={{ color: "var(--ion-color-primary)", textDecoration: "underline", cursor: "pointer" }}>
                                    angezeigte Flüge: {this.state.airportString}
                                </p>
                            </IonText>
                            <div className={`${this.state.showAirportInputs ? "" : "ion-hide"}`}>
                                <IonItem>
                                    <IonLabel position="floating">Flugnummer</IonLabel>
                                    <IonInput
                                        onIonChange={(e) => this.setFlightNo(e.detail.value)}
                                        value={this.state.flightNo}
                                        inputmode="text"
                                        autocapitalize="characters"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="floating">Startflughafen</IonLabel>
                                    <IonInput
                                        onIonChange={(e) => this.setAirportFrom(e.detail.value)}
                                        value={this.state.airportFromIataCode}
                                        inputmode="text"
                                        autocapitalize="characters"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="floating">Zielflughafen</IonLabel>
                                    <IonInput
                                        onIonChange={(e) => this.setAirportTo(e.detail.value)}
                                        value={this.state.airportToIataCode}
                                        inputmode="text"
                                        autocapitalize="characters"
                                    ></IonInput>
                                </IonItem>
                                <IonButton className="ion-margin-bottom" mode="ios" onClick={() => this._searchFlightsClicked()}>
                                    Ansicht aktualisieren
                                </IonButton>
                            </div>

                            <div className="products-productlist-container">{this._renderProductList()}</div>

                            <NewCalendarModal
                                isOpen={this.state.showCalendar}
                                showCloseButton={true}
                                onCloseButtonPressed={() => {
                                    this.setState({ showCalendar: false });
                                }}
                                startDate={this.state.currentCalendarStartDate}
                                endDate={this.state.currentCalendarEndDate}
                                onChange={(v) => {
                                    this._calendarChange(v);
                                }}
                                onSuccess={() => {
                                    this._setNewDates();
                                }}
                            />

                            {/* <IonFooter className="products-footer">
                                <ResponsiveContainer className="products-footer-pie-container" height={150} width={"100%"}>
                                    <PieChart>
                                        <Pie
                                            isAnimationActive={false}
                                            label={this._renderLabel}
                                            paddingAngle={3}
                                            data={this.state.pieProducts}
                                            dataKey="data"
                                            cx="50%"
                                            cy="100%"
                                            startAngle={180}
                                            endAngle={0}
                                            innerRadius={70}
                                            outerRadius={100}
                                        >
                                            {this.state.pieProducts.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </IonFooter> */}
                        </div>
                        <SettingsMenu
                            textButtonOnClick={() => {
                                this._openCalendar();
                            }}
                            dateString={`${
                                this.state.startDate && this.state.endDate
                                    ? this.state.startDate.toLocaleString("de-DE").slice(0, -10) +
                                      " - " +
                                      this.state.endDate.toLocaleString("de-DE").slice(0, -10)
                                    : ""
                            }`}
                            buttonbarOptions={["Top 3", "Top 5", "Top 10", "alle"]}
                            buttonbarValues={[3, 5, 10, 10000]}
                            buttonbarActiveIndex={this.state.optionButtonbar}
                            buttonbarOnChange={(idx, value) => {
                                this._changeTopFilter(idx, value);
                            }}
                            segmentElements={["Umsatz", "Anzahl"]}
                            segmentActiveIndex={this.state.activeSegmentIndex}
                            segmentOnChange={(idx) => {
                                this._changeSegmentFilter(idx);
                            }}
                            url={this._createShareLink()}
                        />
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn,
        settings: state.settings,
        basicAuth: state.userinfos.basicAuth,
    };
};

export default connect(mapStateToProps, null)(Products);
