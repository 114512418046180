import {
    SET_USERNAME,
    SET_IS_LOGGEDIN,
    SET_BASIC_AUTH,
    SET_ROLE_ADMIN,
    SET_ROLE_USER,
    SET_API_KEY,
    DESTROY_SESSION
} from '../constants/userconstants';

import {
    SET_SETTINGS_STANDARD_REVENUE_SEGMENT,
    SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT,
    SET_SETTINGS_STANDARD_PRODUCTS_TOP,
    SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT,
    SET_SETTINGS_STANDARD_CREWMEMBERS_TOP,
    SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT,
    SET_SETTINGS_STANDARD_FLIGHTS_TOP
} from '../constants/settingsconstants';

const initialState = {
    userinfos: {
        username: '',
        loggedIn: false,
        basicAuth: '',
        roleUser: false,
        roleAdmin: false,
        apiKey: '',
    },

    settings: {
        darkMode: false,

        standardRevenueSegment: '2',
        standardRevenueDateStart: null,
        standardRevenueDateEnd: null,

        standardProductsSegment: '0',
        standardProductsTopIndex: 0,
        standardProductsTopValue: 3,
        standardProductsDateStart: null,
        standardProductsDateEnd: null,

        standardFlightSegment: '0',
        standardFlightTopIndex: 0,
        standardFlightTopValue: 3,
        standardFlightDateStart: null,
        standardFlightDateEnd: null,

        standardCrewmembersSegment: '0',
        standardCrewmembersTopIndex: 0,
        standardCrewmembersTopValue: 3,
        standardCrewmembersDateStart: null,
        standardCrewmembersDateEnd: null,

        standardSalesDateStart: null,
        standardSalesDateEnd: null,
    }
}

export default function rootReducer(state = initialState, action) {
    if (action.type === SET_USERNAME) {
        let copy = { ...state.userinfos };
        copy.username = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === SET_IS_LOGGEDIN) {
        let copy = { ...state.userinfos };
        copy.loggedIn = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === SET_BASIC_AUTH) {
        let copy = { ...state.userinfos };
        copy.basicAuth = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === SET_ROLE_ADMIN) {
        let copy = { ...state.userinfos };
        copy.roleAdmin = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === SET_ROLE_USER) {
        let copy = { ...state.userinfos };
        copy.roleUser = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === SET_API_KEY) {
        let copy = { ...state.userinfos };
        copy.apiKey = action.payload;

        return Object.assign({}, state, {
            userinfos: copy
        });
    } else if (action.type === DESTROY_SESSION) {
        return initialState
    } else if (action.type === SET_SETTINGS_STANDARD_REVENUE_SEGMENT) {
        let copy = { ...state.settings };
        copy.standardRevenueSegment = action.payload;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT) {
        let copy = { ...state.settings };
        copy.standardProductsSegment = action.payload;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_PRODUCTS_TOP) {
        let copy = { ...state.settings };
        copy.standardProductsTopIndex = action.payload.index;
        copy.standardProductsTopValue = action.payload.value;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT) {
        let copy = { ...state.settings };
        copy.standardCrewmembersSegment = action.payload;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_CREWMEMBERS_TOP) {
        let copy = { ...state.settings };
        copy.standardCrewmembersTopIndex = action.payload.index;
        copy.standardCrewmembersTopValue = action.payload.value;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT) {
        let copy = { ...state.settings };
        copy.standardFlightSegment = action.payload;

        return Object.assign({}, state, {
            settings: copy
        });
    } else if (action.type === SET_SETTINGS_STANDARD_FLIGHTS_TOP) {
        let copy = { ...state.settings };
        copy.standardFlightTopIndex = action.payload.index;
        copy.standardFlightTopValue = action.payload.value;

        return Object.assign({}, state, {
            settings: copy
        });
    }

    return state;
}