export const SET_SETTINGS = "SET_SETTINGS";

export const SET_SETTINGS_DARK_MODE = "SET_SETTINGS_DARK_MODE";

export const SET_SETTINGS_STANDARD_REVENUE_SEGMENT = "SET_SETTINGS_STANDARD_REVENUE_SEGMENT";

export const SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT = "SET_SETTINGS_STANDARD_PRODUCTS_SEGMENT";
export const SET_SETTINGS_STANDARD_PRODUCTS_TOP = "SET_SETTINGS_STANDARD_PRODUCTS_TOP";

export const SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT = "SET_SETTINGS_STANDARD_CREWMEMBERS_SEGMENT";
export const SET_SETTINGS_STANDARD_CREWMEMBERS_TOP = "SET_SETTINGS_STANDARD_CREWMEMBERS_TOP";

export const SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT = "SET_SETTINGS_STANDARD_FLIGHTS_SEGMENT";
export const SET_SETTINGS_STANDARD_FLIGHTS_TOP = "SET_SETTINGS_STANDARD_FLIGHTS_TOP";