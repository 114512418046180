import { IonModal, IonText, IonButton, IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

import CustomModal from './CustomModal';
import './EditModal.css';

import { renderPrice } from '../Helper';

export default class EditModal extends CustomModal {
    state = {
        article_name_ger: this.props.obj.name,
        article_name_en: this.props.obj.name,
        article_category: this._getCategoryName(this.props.obj.idCategory),
        article_category_id: this.props.obj.idCategory,
        article_code: this.props.obj.code,
        article_price: this.props.obj.price1,

        INIT_article_name_ger: this.props.obj.name,
        INIT_article_name_en: this.props.obj.name,
        INIT_article_category: this._getCategoryName(this.props.obj.idCategory),
        INIT_article_category_id: this.props.obj.idCategory,
        INIT_article_code: this.props.obj.code,
        INIT_article_price: this.props.obj.price1
    }

    componentDidUpdate() {
        if (this.props.obj.name !== this.state.INIT_article_name_ger) {
            this.setState({
                INIT_article_name_ger: this.props.obj.name,
                article_name_ger: this.props.obj.name
            })
        }

        if (this.props.obj.name !== this.state.INIT_article_name_en) {
            this.setState({
                INIT_article_name_en: this.props.obj.name,
                article_name_en: this.props.obj.name
            })
        }

        if (this._getCategoryName(this.props.obj.idCategory) !== this._getCategoryName(this.state.INIT_article_category_id)) {
            this.setState({
                INIT_article_category: this._getCategoryName(this.props.obj.idCategory),
                article_category: this._getCategoryName(this.props.obj.idCategory)
            })
        }

        if (this.props.obj.idCategory !== this.state.INIT_article_category_id) {
            this.setState({
                INIT_article_category_id: this.props.obj.idCategory,
                article_category_id: this.props.obj.idCategory
            })
        }

        if (this.props.obj.code !== this.state.INIT_article_code) {
            this.setState({
                INIT_article_code: this.props.obj.code,
                article_code: this.props.obj.code
            })
        }

        if (this.props.obj.price1 !== this.state.INIT_article_price) {
            this.setState({
                INIT_article_price: this.props.obj.price1,
                article_price: this.props.obj.price1
            })
        }
    }

    _getCategoryName(catID) {
        let catsLongName = this.props.categories[2];
        let catsShortName = this.props.categories[1];
        let catsIDs = this.props.categories[0];

        if (catsIDs.includes(catID)) {
            let i = catsIDs.indexOf(catID);
            return `${catsLongName[i]} (${catsShortName[i]})`
        }
    }

    _renderCategories() {
        let ret = [];

        const catIDs = this.props.categories[0];
        const catShortNames = this.props.categories[1];
        const catLongNames = this.props.categories[2];

        for (let i = 0; i < catIDs.length; i++) {
            ret.push(
                <IonSelectOption value={catIDs[i]} key={i}>
                    {`${catLongNames[i]} (${catShortNames[i]})`}
                </IonSelectOption>
            )
        }

        return ret;
    }

    _doesProductShortNameExist(code) {
        let found = false;
        for (let i = 0; i < this.props.prods.length; i++) {
            if (this.props.products[i].code === code) {
                found = true;
                break;
            }
        }

        return found;
    }

    _doesProductNameExist(name) {
        let found = false;
        for (let i = 0; i < this.props.prods.length; i++) {
            if (this.props.products[i].name === name) {
                found = true;
                break;
            }
        }

        return found;
    }

    _change() {
        let retObj = {
            name: this.props.obj.name,
            code: this.props.obj.code,
            description: '',
            extras: [],
            id: this.props.obj.id,
            idCategory: this.props.obj.idCategory,
            price1: this.props.obj.price1,
            price2: 0.0
        };

        if (this.state.article_name_ger && this.state.article_name_ger.length > 0) {
            retObj.name = this.state.article_name_ger;
        }

        if (this.state.article_code && this.state.article_code.length > 0) {
            retObj.code = this.state.article_code;
        }

        if (this.state.article_category_id) {
            retObj.idCategory = this.state.article_category_id;
        }

        if (this.state.article_price && !isNaN(parseFloat(this.state.article_price.toString().replace(',', '.')))) {
            retObj.price1 = parseFloat(this.state.article_price.toString().replace(',', '.'));
        }

        this.props.onChangeButtonPressed(retObj);
    }

    render() {
        return (
            <>
                <IonModal
                    className='custommodal-container'
                    isOpen={this.props.isOpen}
                    backdropDismiss={false}>

                    <div className='custommodal-header-container'>
                        <div className='custommodal-closebutton-container'>
                            {this._renderCloseButton()}
                        </div>
                        <div className='custommodal-headline-container'>
                            <IonText className='custommodal-headline'>{this.props.headlineValue}</IonText>
                        </div>
                    </div>

                    <IonList>
                        {(this.props.obj.name) ?
                            <IonItem>
                                <IonLabel position='floating'>
                                    Name (deutsch)
                            </IonLabel>
                                <IonInput value={this.state.article_name_ger} onIonChange={(e) => { this.setState({ article_name_ger: e.detail.value }) }}>
                                </IonInput>
                            </IonItem> : null}

                        {(this.props.obj.name) ?
                            <IonItem>
                                <IonLabel position='floating'>
                                    Name (englisch)
                            </IonLabel>
                                <IonInput value={this.state.article_name_en} onIonChange={(e) => { this.setState({ article_name_en: e.detail.value }) }}>
                                </IonInput>
                            </IonItem> : null}

                        {(this.props.obj.idCategory) ?
                            <IonItem>
                                <IonLabel position='floating'>
                                    Kategorie
                            </IonLabel>
                                <IonSelect
                                    onIonChange={(e) => this.setState({ article_category_id: e.detail.value })}
                                    value={this.state.article_category_id}>
                                    {this._renderCategories()}
                                </IonSelect>
                            </IonItem> : null}

                        {(this.props.obj.code) ?
                            <IonItem>
                                <IonLabel position='floating'>
                                    Kürzel
                                </IonLabel>
                                <IonInput
                                    maxlength={4}
                                    value={this.state.article_code}
                                    onIonChange={(e) => { this.setState({ article_code: e.detail.value.toUpperCase() }) }}>
                                </IonInput>
                            </IonItem> : null}

                        {(this.props.obj.price1) ?
                            <IonItem>
                                <IonLabel position='floating'>
                                    Preis
                            </IonLabel>
                                <IonInput
                                    value={this.state.article_price}
                                    onIonChange={(e) => { this.setState({ article_price: e.detail.value }) }}
                                    inputmode='decimal'>
                                </IonInput>
                                <IonLabel
                                    position='stacked'
                                    slot='end'>
                                    Vorschau: {(this.state.article_price) ? renderPrice(parseFloat(this.state.article_price.toString().replace(',', '.')), 'EUR') : ''}
                                </IonLabel>
                            </IonItem> : null}
                    </IonList>

                    <div className='editmodal-button-container'>
                        <IonButton
                            mode='ios'
                            disabled={this.state.article_name_en.length === 0 ||
                                this.state.article_name_ger.length === 0}
                            onClick={() => { this._change() }}>
                            Änderungen speichern
                    </IonButton>
                    </div>
                </IonModal>
            </>
        )
    }
}