import { IonText } from '@ionic/react';
import React from 'react';

import './BarItem.css'

import FlightDetailModal from './FlightDetailModal';

export default class BarItem extends React.Component {
    state = {
        showDetailsModal: false
    }

    _renderDetails() {
        if (this.props.hint === 'number') {
            return (
                <>
                    <span className='revenue active'>{this.props.number}</span>
                    <span className='number'>({this.props.revenue})</span>
                </>
            )
        } else {
            return (
                <>
                    <span className='revenue active'>{this.props.revenue}</span>
                    <span className='number'>({this.props.number})</span>
                </>
            )
        }
    }

    render() {
        return (
            <>
                <div className='baritem-container' onClick={() => { this.setState({ showDetailsModal: true }) }}>
                    <div className='baritem-label'>
                        <IonText className='baritem-label-title'>
                            {this.props.title}
                        </IonText>
                        <IonText className='baritem-label-hint'>
                            {this._renderDetails()}
                        </IonText>
                    </div>
                    <div className='baritem-bar'>
                        <div className='baritem-bar-active' style={{ width: `${this.props.percent}%` }}>
                        </div>
                    </div>
                </div>

                <FlightDetailModal
                    isOpen={this.state.showDetailsModal && this.props.showDetailModal}
                    showCloseButton={true}
                    headlineValue={this.props.title}
                    onCloseButtonPressed={() => { this.setState({ showDetailsModal: false }) }}
                    onDidDismiss={() => { this.setState({ showDetailsModal: false }) }}>

                </FlightDetailModal>
            </>
        )
    }
}